import { IconButton, Textarea } from "@material-tailwind/react";
import { useForm } from "react-hook-form";

export function MessageInput({ onSubmit, isSubmitting, disabled }) {
    const { register, handleSubmit, resetField } = useForm({
        defaultValues: { content: '' }
    });

    const handleFormSubmit = async (data) => {
        await onSubmit(data);
        resetField("content");
    };

    return (
        <div className="h-[10%] flex items-center p-2 space-x-1">
            <Textarea
                rows={1}
                resize={false}
                label="Wiadomość"
                className="min-h-full"
                containerProps={{ className: "h-full" }}
                {...register("content", { required: true, minLength: 1 })}
            />
            <IconButton onClick={handleSubmit(handleFormSubmit)} variant="text" disabled={isSubmitting || disabled}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>
            </IconButton>
        </div>
    );
}
