import {Button, Card, Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import {Suspense, useContext, useEffect, useState} from "react";
import useGetData from "../../../utils/useGetData";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../AuthProvider";
import {useAlert} from "../../DisimisibleAlert";
import api from "../../../utils/api";
import {DefaultSkeletonPage} from "../../Skeletons";

export function DeleteAnnouncementDialog({header, body, open, setOpen, callback}) {
    const handleOpen = () => setOpen(!open);

    return (
        <Dialog handler={handleOpen} open={open}>
            <DialogHeader>
                Czy na pewno chcesz usunąć?
            </DialogHeader>

            <DialogBody>
                <Typography className='text-2xl text-black mb-1'>{header}</Typography>
                <Typography>{body}</Typography>
            </DialogBody>

            <DialogFooter className='gap-3'>
                <Button color='gray' onClick={handleOpen}>Anuluj</Button>
                <Button color='red' onClick={() => {
                    handleOpen();
                    callback();
                }}>Tak</Button>
            </DialogFooter>
        </Dialog>
    )
}

function UserAnnouncement({header, body, announcementId, student = false, editButtons = false ,refetchData}) {
    const navigate = useNavigate();
    let url = `/ogloszenie/${announcementId}`;
    let editUrl = `/edytuj/${announcementId}`;
    const showAlert = useAlert();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    if (student) {
        url = `/ogloszenie/u/${announcementId}`
        editUrl = `/edytuj/u/${announcementId}`;
    }

    const deleteAnnouncement = async () => {
        try {
            await api.delete(student ? `/student-announcement/${announcementId}` : `/tut-announcement/${announcementId}`);
            showAlert("Ogłoszenie zostało usunięte.");
            refetchData();
        } catch (e) {
            showAlert("Wystąpił problem z usunięciem ogłoszenia.");
        }
    }

    return (
        <>
            {showDeleteDialog && <DeleteAnnouncementDialog body={body} header={header} open={showDeleteDialog} setOpen={setShowDeleteDialog}
                                       id={announcementId} callback={deleteAnnouncement}/>}
            <Card className='p-4 border-t border-t-blue-500 rounded-none my-2'>
                <div className='flex'>
                    <Typography variant='h6'>
                        {header}
                    </Typography>

                    {editButtons && (
                        <div className='ml-auto min-w-fit'>
                            <button className='h-4 w-4 hover:-translate-y-0.5 transition-all text-red-400 hover:text-red-700'
                                onClick={() => setShowDeleteDialog(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
                                </svg>
                            </button>

                            <button className='h-4 w-4 ml-3 hover:-translate-y-0.5 transition-all text-orange-500 hover:text-orange-700' onClick={() => navigate(editUrl)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"/>
                                </svg>
                            </button>
                        </div>
                    )}
                </div>


                <Typography className='mt-5 mb-1'>
                    {body}
                </Typography>
                <Button variant="text" className="w-fit flex gap-2 self-end" onClick={() => navigate(url)}>
                    Ogłoszenie
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-4 w-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                    </svg>
                </Button>
            </Card>
        </>
    );
}

function UserAnnouncements({url, isStudent}) {
    const {user} = useContext(AuthContext);
    const [reload, setReload] = useState(false);
    let fetchedData = useGetData(url, reload);
    const [data, setData] = useState();

    useEffect(() => {
        setData(fetchedData);
    }, [fetchedData]);

    const refetchData = () => {
        setReload(prev => !prev);
    };

    if (data?.length === 0) {
        return (
            <Typography variant='paragraph' className='ml-5 text-blue-gray-700'>
                Brak ogłoszeń
            </Typography>
        )
    }

    return (
        <>
            {data?.map((ua, index) => {
                const headerContent = `${ua.category_name} - ${ua.price} zł/h`;
                return (
                    <UserAnnouncement
                        key={index}
                        body={ua.short_description}
                        header={headerContent}
                        announcementId={ua.id}
                        student={isStudent}
                        editButtons={user?.id === ua.user_id}
                        refetchData={refetchData}
                    />
                );
            })}
        </>
    );
}

export default function UserAnnouncementsPanel({url, isStudent}) {
    return (
        <Suspense fallback={<DefaultSkeletonPage />}>
            <UserAnnouncements url={url} isStudent={isStudent}/>
        </Suspense>
    );
}
