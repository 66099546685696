import React, {createContext, forwardRef, useContext, useRef, useState} from "react";

const SearchContext = createContext();

// data oraz fetcher muszą zwracac tablice obiektów {value, label} lub {label}, label musi byc str, mozliwe rowniez jest uzycie {key, label, sublabel}
export const SearchInput = forwardRef(({
                                                               placeholder = 'Wyszukaj...',
                                                               onChange,
                                                               onBlur,
                                                               onFocus,
                                                               name = 'searchInput',
                                                               defaultInputValue = '',
                                                               data = [],
                                                               fetcher = () => {},
                                                               minCharacters = 0,
                                                           }, ref) => {
    const [active, setActive] = useState(false);
    const [inputValue, setInputValue] = useState(defaultInputValue);
    const [suggestions, setSuggestions] = useState();

    const innerRef = useRef(null);
    const inputRef = ref || innerRef;

    const getNewSuggestions = async (text) => {
        if (data.length === 0) {
            data = await fetcher(text);
        }

        let newSuggestions = data.filter(d =>
            text.length >= minCharacters &&
            d.label.toLowerCase().startsWith(text.toLowerCase())
        );

        if(newSuggestions.length > 15) {
            newSuggestions = newSuggestions.slice(0, 15);
        }

        setSuggestions(newSuggestions);
    };

    const handleClear = () => {
        setInputValue('');
        if (onChange) {
            onChange({ target: { value: '' } });
        }
        // Ustawienie fokusu na input
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const contextValue = {
        active,
        setActive,
        inputValue,
        setInputValue,
        setValue: onChange,
    };

    return (
        <SearchContext.Provider value={contextValue}>
            <div className={`relative ${active ? 'z-[999]': 'z-50'} w-full`}>
                <div className='relative'>
                    <input
                        ref={inputRef} // Ustawienie referencji
                        className='bg-white
                            w-full h-10 px-4 py-2 pl-4 pr-4 // Padding for the icon and clear button
                            border border-gray-300
                            rounded-md shadow-sm
                            focus:outline-none
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                            transition
                            text-gray-700
                            placeholder-gray-400'
                        placeholder={placeholder}
                        name={name}
                        value={inputValue}
                        onFocus={(e) => {
                            setActive(true);
                            onFocus && onFocus(e);
                        }}
                        onBlur={(e) => {
                            setActive(false);
                            onBlur && onBlur(e);
                        }}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            onChange && onChange(e);
                            getNewSuggestions(e.target.value);
                        }}
                        autoComplete="off" // Disable browser suggestions
                    />
                    {inputValue && (
                        <button
                            type="button"
                            className='absolute inset-y-0 right-0 flex items-center pr-3'
                            onClick={handleClear}
                            aria-label="Clear input"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-6 h-6 text-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    )}
                </div>
                {active && <SuggestionsBlock suggestions={suggestions} />}
            </div>
        </SearchContext.Provider>
    );
})

function SuggestionsBlock({suggestions}) {
    if (!suggestions || suggestions.length === 0) {
        return null;
    }

    const suggestionsMap = suggestions.map(s => {
        if (s.key) {
            return (<Suggestion suggestionValue={s.key} subLabel={s.subLabel}>{s.label}</Suggestion>);
        } else {
            return (<Suggestion suggestionValue={s.label} subLabel={s.subLabel}>{s.label}</Suggestion>);
        }
    })

    return (
        <div className='absolute top-11 left-0
            w-full
            bg-white
            border border-gray-300
            rounded-md
            shadow-lg
            z-50
            overflow-hidden'>

            {suggestionsMap}
        </div>
    );
}

function Suggestion({ children, suggestionValue = null, subLabel = null }) {
    const { setActive, setInputValue, setValue } = useContext(SearchContext);

    return (
        <div
            className='
                w-full px-4 py-2
                text-gray-700
                cursor-pointer
                hover:bg-blue-500 hover:text-white
                transition-all duration-200
                first:rounded-t-md last:rounded-b-md
                '
            onMouseDown={() => {
                setInputValue(children);
                setValue && setValue(suggestionValue);
                setActive(false);
            }}
        >
            {children}
            <span className='ml-3 text-sm text-blue-gray-300'>{subLabel}</span>
        </div>
    );
}
