import {useState} from "react";
import {Typography} from "@material-tailwind/react";

function NestingAccordionIcon({open}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
        </svg>
    );
}

export default function NestingAccordion({open: defaultOpen = false, header: headerText = "", className, children}) {
    const [open, setOpen] = useState(defaultOpen)

    function handleHeaderClick() {
        setOpen(!open);
    }

    return (
        <div className={`mb-4 ${className}`}>
            <div className='flex cursor-pointer' onClick={handleHeaderClick}>
                <Typography variant='h5' className={`transition-all ease-in-out duration-500 text-blue-gray-900 ${!open && 'text-blue-gray-700 hover:text-blue-gray-900' }`}>{headerText}</Typography>
                <div className='self-center ml-auto'>
                    <NestingAccordionIcon open={open} />
                </div>
            </div>

            <hr className={`bg-blue-gray-900 h-0.5 my-3 transition-all ease-in-out duration-500 ${!open && 'bg-blue-gray-200'}`} />

            <div className={`mt-3 duration-800 ease-in-out transition-all ${open ? 'max-h-screen duration-900 p-2' : 'max-h-0 overflow-hidden' }`}>
                {children}
            </div>
        </div>
    )
}