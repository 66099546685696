import {Card, Typography} from "@material-tailwind/react";
import Link from "../../Link";


export default function Contact() {
    return (
        <div>
            <Typography variant='h3' className='mb-2'>
                Kontakt
            </Typography>

            <Typography variant='lead'>
                Masz pytania dotyczące naszej strony, problem z dodaniem ogłoszenia, czy może potrzebujesz pomocy technicznej? Skontaktuj się z nami:
            </Typography>
            <Typography variant={"paragraph"}>
                <Link href={'mailto:kontakt@zdaj-to.pl'}>kontakt@zdaj-to.pl</Link>
            </Typography>
        </div>
    )
}