import {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import {ConversationList} from "../components/pageParts/messages/ConversationList";
import {AuthContext} from "../components/AuthProvider";
import {useAlert} from "../components/DisimisibleAlert";
import api from "../utils/api";
import useGetData from "../utils/useGetData";
import {MessageInput} from "../components/pageParts/messages/MessageInput";
import {Message} from "../components/pageParts/messages/Message";
import {ConversationHeader} from "../components/pageParts/messages/ConversationHeader";
import {useResponsive} from "../components/ResponsiveProvider";
import {useFooterVisibility} from "./Layout";

const refreshTimeMillis = 10000;

export default function Messages() {
    const [reload, setReload] = useState(false);
    const { id } = useParams();
    const { user } = useContext(AuthContext);
    const showAlert = useAlert();
    const endOfMessagesRef = useRef();
    const {isMobile} = useResponsive();
    const { setFooterVisibility } = useFooterVisibility();

    const onSubmit = async (data) => {
        try {
            await api.post("/message", { conversation_id: id, sender_id: user.id, content: data.content });
            setReload((prev) => !prev);
        } catch {
            showAlert("Nie udało się wysłać wiadomości.");
        }
    };

    const data = useGetData(`/conversation/user`, reload);
    const conversationMessages = useGetData(`conversation/messages/${id}`, reload);
    const conversationMsgSnapshotRef = useRef([]);

    const chosenBox = data?.find((c) => c.id == id);
    const boxName = chosenBox
        && (user.id === chosenBox.user_id_1
            ? `${chosenBox?.user2_firstname ?? ''} ${chosenBox?.user2_lastname ?? ''}`.trim()
            : `${chosenBox?.user1_firstname ?? ''} ${chosenBox?.user1_lastname ?? ''}`.trim()
    ) || null;

    useEffect(() => {
        const intervalId = setInterval(() => {
            conversationMsgSnapshotRef.current = conversationMessages;
            setReload((prev) => !prev);
        }, refreshTimeMillis);
        return () => clearInterval(intervalId);
    }, [reload]);

    useEffect(() => {
        if (JSON.stringify(conversationMsgSnapshotRef.current) !== JSON.stringify(conversationMessages)) {
            endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [conversationMessages]);


    useEffect(() => {
        setFooterVisibility(false);
        return () => setFooterVisibility(true);
    }, [setFooterVisibility]);

    return (
        <div className="h-[83vh] flex justify-center md:py-16 bg-[#fefefe]">
            <div className="w-full md:w-4/5 h-full rounded shadow-none md:shadow-lg flex p-1 border-none md:border border-blue-gray-100">
                <div className={`max-h-full w-full md:w-1/4 rounded-l border-r border-blue-gray-200 ${chosenBox && isMobile && 'hidden'}`}>
                    <Typography as="div" className="text-center py-3 border-b text-xl font-bold">
                        Konwersacje
                    </Typography>
                    <div className="overflow-auto h-[calc(100%-58px)]">
                        <ConversationList conversations={data ?? []} userId={user.id} selectedId={id} />
                    </div>
                </div>
                <div className={`w-full md:w-3/4 ${!chosenBox && isMobile && 'hidden'}`}>
                    <ConversationHeader topic={chosenBox?.topic} boxName={boxName} />
                    <div className="w-full h-[80%] p-4 overflow-x-hidden overflow-y-auto">
                        {conversationMessages?.map((m) => (
                            <Message key={m.id} value={m.content} me={m.sender_id == user.id} />
                        ))}
                        <div ref={endOfMessagesRef} />
                    </div>
                    <MessageInput onSubmit={onSubmit} isSubmitting={!id} disabled={!id} />
                </div>
            </div>
        </div>
    );
}
