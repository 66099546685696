import {Button, Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import useGetData from "../../../utils/useGetData";

export default function HomeNewestAnnouncements() {

    const data = useGetData('/tut-announcements/newest')

    return (
        <div className='pt-4'>
            <Typography variant={'h4'} className='ml-7 mt-2'>
                Najnowsze ogłoszenia
            </Typography>
            <hr className="my-3"/>

            <div className='w-full flex justify-center'>
                <div className='flex flex-wrap w-[95%] gap-2'>
                    {data && data.map(d => (
                        <Card className="min-w-[100px] max-w-[270px] p-0 bg-[#fefefe]">
                            <CardBody>
                                <Typography variant="h6" color="blue-gray">
                                    {d.category_name}
                                </Typography>
                                <Typography variant="small" color="blue-gray" className="mb-3">
                                    {d.firstname}
                                </Typography>
                                <Typography variant='small'>
                                    {d.short_description}
                                </Typography>
                            </CardBody>
                            <CardFooter className="p-0">
                                <a href={`/ogloszenie/${d.id}`} className="inline-block">
                                    <Button size="sm" variant="text"
                                            className="absolute bottom-3 left-3 flex items-center gap-1">
                                        Więcej
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={2}
                                            stroke="currentColor"
                                            className="h-4 w-4"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                            />
                                        </svg>
                                    </Button>
                                </a>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    )
}