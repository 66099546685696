import {ThemeProvider, Typography} from "@material-tailwind/react";
import Link from "../../Link";

export default function SupportUs({theme}) {

    return (
        <ThemeProvider value={theme}>
            <Typography variant='h3' className='mb-2'>
                Wesprzyj nas
            </Typography>


            <Typography variant="lead">
                Jeśli podoba Ci się nasza strona i chciałbyś przyczynić się do jej dalszego rozwoju, możesz nas wesprzeć na Patronite!{" "}
                <Link href="https://suppi.pl/zdaj-to" target="_blank" rel="noopener">
                    Wesprzyj nas
                </Link>
            </Typography>

            <Typography variant="lead">
                Strona jest tworzona i zarządzana przez jedną osobę, więc każde wsparcie jest nieocenione. Dzięki Twojej pomocy będziemy mogli:
            </Typography>

            <Typography variant="lead">
                Twoje wsparcie finansowe pozwoli nam na zakup lepszego sprzętu, pokrycie kosztów utrzymania serwera oraz wprowadzenie innowacji, które uczynią nasz serwis jeszcze bardziej przyjaznym dla użytkowników.
            </Typography>

            <Typography variant="lead">
                Dziękujemy za Twoją pomoc i wsparcie! Każda złotówka się liczy i przybliża nas do realizacji naszych celów.
            </Typography>
        </ThemeProvider>
    )
}