import {backendUrl} from "./constants";

export function toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}

export function checkUserActivity(lastActiveDate) {
    const lastActive = new Date(lastActiveDate);
    const now = new Date();

    const timeDifference = now - lastActive;
    const daysSinceActive = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysSinceActive < 0) {
        return 'Data ostatniej aktywności jest w przyszłości.';
    } else if (daysSinceActive === 0) {
        return 'Dziś';
    } else if (daysSinceActive === 1) {
        return 'Wczoraj';
    } else if (daysSinceActive <= 30) {
        return `${daysSinceActive} dni temu`;
    } else {
        return 'Ponad miesiąc temu';
    }
}

export function getNotificationName(tag) {
    switch (tag) {
        case 'message':
            return 'Nowa wiadomość'
            break;
        case 'review':
            return 'Nowa opinia'
            break;
        case 'system':
            return 'Serwis'
            break;
        case 'other':
            return 'Inne'
            break;
    }
}

// path - path of api with / at 0 char
export function createApiLink(path) {
    return `${backendUrl}${path}`;
}