import React, {createContext, Suspense, useContext, useState} from 'react';
import {Button, Card, Typography} from "@material-tailwind/react";
import Divider from "../components/Divider";
import {useNavigate, useParams} from "react-router-dom";
import useGetData from "../utils/useGetData";
import {createApiLink, toTitleCase} from "../utils/functions";
import StudentAnnouncementSkeleton from "./StudentAnnouncementSkeleton";
import {AuthContext} from "../components/AuthProvider";
import {DeleteAnnouncementDialog} from "../components/pageParts/profile/UserAnnouncements";
import api from "../utils/api";
import {useAlert} from "../components/DisimisibleAlert";
import NewConversationDialog from "../components/NewConversationDialog";

function LeftStudentAnnouncementPanel() {
    const data = useData();
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const announcementId = useParams().id;
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState(false);
    const showAlert = useAlert();

    const handleWriteMessage = async () => {
        if (!user) {
            showAlert('Aby napisać wiadomość do korepetyrora, wpierw zaloguj się.');
        } else {
            setMessageDialog(true);
        }
    }

    const deleteStudentAnnouncement = async () => {
        try {
            await api.delete(`/student-announcement/${announcementId}`);
            navigate('/')
            showAlert("Ogłoszenie zostało usunięte.");
        } catch (e) {
            showAlert("Wystąpił problem z usunięciem ogłoszenia.");
        }
    }

    return (
        <>
            {user && <NewConversationDialog open={messageDialog} handleOpen={() => setMessageDialog(!messageDialog)}
                                    name={data?.firstname} receiverId={data?.user_id}/>}
            <DeleteAnnouncementDialog id={announcementId} open={showDeleteDialog} setOpen={setShowDeleteDialog}
                                      header={data?.category_name}
                                      body={data?.description} callback={deleteStudentAnnouncement}/>

            <div className='w-full md:min-w-72 md:w-72 flex flex-col'>
                <div
                    className='flex w-full justify-center bg-gradient-to-r from-cyan-500 to-blue-500 py-3 px-1 rounded-sm text-white'>
                    <div className='w-fit'>
                        <a href={`/profil/${data?.user_id}`}>
                            <img
                                className="h-48 w-48 rounded-full object-cover object-center"
                                src={createApiLink(data?.profile_image)}
                            />

                            <Typography variant='lead' className='text-center font-bold mt-2'>
                                {data?.firstname} {data?.lastname}
                            </Typography>
                        </a>
                        {user?.id === data?.user_id && (
                            <div className='flex justify-center gap-1'>
                                <button
                                    className='h-4 w-4 hover:-translate-y-0.5 transition-all text-red-400 hover:text-red-700'
                                    onClick={() => setShowDeleteDialog(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5}
                                         stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
                                    </svg>
                                </button>

                                <button
                                    className='h-4 w-4 ml-3 hover:-translate-y-0.5 transition-all text-orange-500 hover:text-orange-700'
                                    onClick={() => navigate(`/edytuj/u/${announcementId}`)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5}
                                         stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"/>
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>


                <Divider className='mt-4'/>
                <Typography variant='lead' className='font-bold mt-2 px-2'>
                    Kontakt
                </Typography>

                <Button className='mt-1 mb-2' variant='gradient' color='blue'
                        onClick={handleWriteMessage}>
                    <div className='flex items-center justify-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                             className="size-6">
                            <path
                                d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z"/>
                            <path
                                d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z"/>
                        </svg>
                        Napisz wiadomość
                    </div>
                </Button>
                {data?.email || data?.profile_phone ? (
                    <Typography variant='paragraph' className='px-5'>
                        {data?.email && (
                            <div className='flex gap-2 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                </svg>

                                {`${data.email}`}
                            </div>
                        )}

                        {data?.profile_phone && (
                            <div className='flex gap-2 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                </svg>

                                {`${data.profile_phone}`}
                            </div>
                        )}
                    </Typography>
                ) : (
                    <Typography variant='paragraph' className='px-5'>
                        Brak danych kontaktowych
                    </Typography>
                )}

                <Divider className='mt-4'/>
                <Typography variant='lead' className='font-bold mt-2 px-2'>
                    Lokalizacja
                </Typography>
                <Typography variant='paragraph' className='px-5'>
                    <div className='flex gap-2 items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        {data && (
                            <Typography>
                                {toTitleCase(data.state)}, {toTitleCase(data.city)}
                            </Typography>
                        )}
                    </div>
                </Typography>

                <Divider/>
                <Typography variant='lead' className='font-bold mt-2 px-2'>
                    Cena
                </Typography>

                {data?.price && (
                    <Typography variant='paragraph' className='px-5'>
                        <div className='flex gap-2 items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            {data?.price} złotych
                        </div>
                    </Typography>
                )}

                {data?.lesson_length && (
                    <Typography variant='paragraph' className='px-5'>
                        <div className='flex gap-2 items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                            {data?.lesson_length} minut
                        </div>
                    </Typography>
                )}

                <Divider/>
                <Typography variant='lead' className='font-bold mt-2 px-2'>
                    Zakres nauczania
                </Typography>
                <ul className='list-disc pl-8'>
                    <li>{data?.scope_name}</li>
                </ul>

                <Divider/>
                <Typography variant='lead' className='font-bold mt-2 px-2'>
                    Lokalizacja zajęć
                </Typography>
                <ul className='list-disc pl-8'>
                    {data?.teachingLocationsNames?.length > 0 ?
                        data?.teachingLocationsNames.map(tl => {
                            return (
                                <li>{tl}</li>
                            )
                        }) : (
                            <Typography>Nie podano</Typography>
                        )}
                </ul>
            </div>
        </>
    );
}

function RightStudentAnnouncementPanel() {
    const data = useData();

    return (
        <div className='w-full md:px-3 mt-4'>
            <Typography variant='h2' className='text-center text-black'>
                {data?.category_name}
            </Typography>

            <Typography variant='lead' className='font-bold mt-2 px-2'>
                Opis
            </Typography>
            <Divider/>
            <Typography variant={"paragraph"} className='px-3 break-words'>
                {data?.description}
            </Typography>
        </div>
    );
}

const DataContext = createContext();

function ApiDataProvider({children}) {
    const {id} = useParams();
    const data = useGetData(`/student-announcement/${id}`);

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    );
}

function useData() {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within an ApiDataProvider');
    }
    return context;
}

export default function StudentAnnouncement() {
    return (
        <Suspense fallback={<StudentAnnouncementSkeleton/>}>
            <ApiDataProvider>
                <Card className='w-4/5 flex flex-col md:flex-row self-center my-4 px-1 py-1 md:px-6 md:py-3'
                      shadow={false}>
                    <LeftStudentAnnouncementPanel/>
                    <RightStudentAnnouncementPanel/>
                </Card>
            </ApiDataProvider>
        </Suspense>
    );
}
