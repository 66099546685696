import {Button, Card, Spinner, Typography} from "@material-tailwind/react";
import Link from "../components/Link";
import { useSearchParams } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import api from "../utils/api";
import {useAlert} from "../components/DisimisibleAlert";

function Activated() {
    return (
        <>
            <Typography variant='h2' className='text-black text-center pb-4'>
                Konto zostało aktywowane
            </Typography>
            <Typography variant='lead' className='text-blue-gray-900 text-center'>
                Twoje konto zostało pomyślnie aktywowane, <Link href='/logowanie'>zaloguj się</Link>
            </Typography>
        </>
    );
}

function NotActivated({userId}) {
    const [seconds, setSeconds] = useState();
    const showAlert = useAlert();
    async function handleResendButton() {
        setSeconds(180);
        try {
            await api.get(`/resend/${userId.current}`);
            showAlert("Mail aktywacyjny został wysłany ponownie.");
        } catch (e) {
            showAlert("Musi minąć 3 minuty zanim wyślemy mail aktywacyjny ponownie.");
        }
    }

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(p => p - 1), 1000);
        }
    }, [seconds, setSeconds]);

    return (
        <>
            <Typography variant='h2' className='text-black text-center pb-4'>
                Nie aktywowano
            </Typography>
            <Typography variant='lead' className='text-blue-gray-900 text-center'>
                Twoje konto <b>nie</b> zostało aktywowane, link stracił ważność.
            </Typography>
            <Button size='sm' className='w-1/2 mx-auto mt-7' color='light-blue' disabled={seconds > 0} onClick={handleResendButton}>
                Wyślij link ponownie {seconds >  0 && `(${seconds})`}
            </Button>
        </>
    );
}

export default function ActivationAccount() {
    const [params] = useSearchParams();
    const [state, setState] = useState('loading');
    const userId = useRef(null);

    useEffect(() => {
        api.get(`/activate?token=${params.get('token')}`)
            .then((r) => {
                if (r.status === 201) {
                    setState('success');
                }  else if (r.status === 200) {
                    setState('already');
                }
            })
            .catch((r) => {
                userId.current = r.response?.data?.accountId;
                setState('error')
            });
    }, [params]);

    return (
        <div className='min-h-[80vh] flex justify-center items-center mt-5 md:mt-6 mb-3'>
            <Card className='w-11/12 md:w-3/5 p-4 h-fit'>
                {state === 'loading' && <Spinner className="h-12 w-12 mx-auto mb-4" />}
                {state === 'success' && <Activated />}
                {state === 'error' && <NotActivated userId={userId} />}
                {state === 'already' && <Typography variant='h2' className='text-center'>Konto jest już aktywowane.</Typography>}
            </Card>
        </div>
    );
}