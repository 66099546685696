import {Breadcrumbs, ThemeProvider, Typography} from "@material-tailwind/react";
import Link from "../../Link";

export default function AboutTeam({theme}) {

    return (
        <>
            <Typography variant='h3' className='mb-2'>
                O Zespole
            </Typography>

            <ThemeProvider value={theme}>
                <Typography variant='lead'>
                    Stwierdzenie "O nas" może być nieco mylące, ponieważ Zdaj-to.pl to projekt prowadzony przez jedną osobę.
                    Chociaż dokładam wszelkich starań, aby serwis działał sprawnie i bezbłędnie, mogą pojawić się drobne
                    usterki. Jeśli napotkasz jakiekolwiek problemy lub masz sugestie dotyczące rozwoju strony, zachęcam do
                    ich zgłaszania <Link href='zgloszenie' >tutaj</Link>. Każda opinia jest dla mnie cenna i pomoże w dalszym doskonaleniu
                    Zdaj-to.pl.
                </Typography>
                <Typography variant='lead'>
                    Jako założyciel i jedyny członek zespołu, jestem otwarty na wszelkie propozycje rozwoju serwisu. Wasze
                    pomysły i uwagi mogą przyczynić się do stworzenia jeszcze lepszego narzędzia edukacyjnego. Propozycje można składać <Link href='/zgloszenie'>tutaj</Link>. Dziękuję za
                    wsparcie i zaufanie!
                </Typography>
            </ThemeProvider>
        </>
    )
}