import {createContext, useContext, useEffect, useState} from "react";
import api from "../utils/api";
import {AuthContext} from "./AuthProvider";

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

const refreshTimeMillis = 60000

export default function NotificationsProvider({ children }) {
    const [notifications, setNotifications] = useState([]);
    const {user} = useContext(AuthContext);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await api.get('/notifications/6/1');
                setNotifications(response.data.notifications.sort((a,b) => new Date(a) - new Date(b)));
            } catch {
                console.error('Błąd podczas pobierania powiadomień');
            }
        };

        if (user) {
            fetchNotifications();
            const intervalId = setInterval(fetchNotifications, refreshTimeMillis);

            return () => clearInterval(intervalId);
        }
    }, []);

    return (
            <NotificationsContext.Provider value={{notifications}}>
                {children}
            </NotificationsContext.Provider>
        );
};
