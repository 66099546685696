import {Spinner} from "@material-tailwind/react";
import {useEffect, useState} from "react";

export default function SpinnerScreen() {
    const [spinnerTextIndex, setSpinnerTextIndex] = useState(0);
    const spinnerText = ['Jeszcze chwila.', 'Jeszcze chwila..', 'Jeszcze chwila...'];

    useEffect(() => {
        const interval = setInterval(() => {
            setSpinnerTextIndex(s => s === 2 ? 0 : s + 1);
        }, 300);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className='w-full min-h-[80vh] flex justify-center items-center'>
            <div className="text-center">
                <Spinner className="h-12 w-12 mx-auto mb-4" />
                {spinnerText[spinnerTextIndex]}
            </div>
        </div>
    );
}