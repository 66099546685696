import {Button, Chip} from "@material-tailwind/react";

const defaultLabels = {
    availability: {
        monday: 'Poniedziałek',
        tuesday: 'Wtorek',
        wednesday: 'Środa',
        thursday: 'Czwartek',
        friday: 'Piątek',
        saturday: 'Sobota',
        sunday: 'Niedziela',
    },
    category: 'Kategoria',
    city: 'Miasto',
    has_image: 'ze zdjęciem',
    has_mail: 'z mailem',
    has_phone: 'z telefonem',
    has_reviews: 'Ma recenzje',
    lesson_len_from: 'Długość lekcji od',
    lesson_len_to: 'Długość lekcji do',
    limit: 'Limit',
    location: {
        tutor: 'u nauczyciela',
        student: 'u ucznia',
        online: 'Online',
    },
    page: 'Strona',
    price_from: 'Cena od',
    price_to: 'Cena do',
    query: 'Wyszukaj',
    scope: {
        kindergarten: 'Przedszkole',
        primary_school: 'Szkoła podstawowa',
        high_school: 'Liceum',
        college: 'Studia',
    },
    state: 'Województwo',
};

export function FilterChipsSection({label, value, onClose}) {

    return (
        <Chip value={value ? `${label}: ${value}` : `${label}`} onClose={onClose} className='w-fit' color='light-blue' />
    )
}

export function FilterChips({data, resetField, resetAll ,className = '', translationLabels = null}) {
    const labels = translationLabels || defaultLabels;
    const chips = [];

    delete data.page;
    delete data.watchRefresh;
    delete data.sort;
    delete data.limit;

    Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object') {
            Object.entries(value).forEach(([subKey, subValue]) => {
                if (subValue) {
                    chips.push({
                        field: `${key}.${subKey}`,
                        label: labels[key]?.[subKey] || `${key} - ${subKey}`,
                    });
                }
            });
        } else if (value) {
            chips.push({
                field: key,
                label: labels[key] || key,
                value: typeof value === 'boolean' ? null : value.toString()
            });
        }
    });

    return (
        <div className={`w-full flex gap-2 py-1 flex-wrap ${className}`}>
            {chips.length > 0 && (
                <button className='py-0.5 bg-red-400 text-white px-2 rounded-lg hover:bg-red-700 transition-all uppercase text-xs font-bold' onClick={resetAll}>Wyczyść</button>
            )}

            {chips.map((chip, index) => (
                <FilterChipsSection
                    key={index}
                    label={chip.label}
                    value={chip.value}
                    onClose={() => {
                        resetField(chip.field);
                    }}
                />
            ))}
        </div>
    );
}
