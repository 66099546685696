import React, {useContext} from "react";
import {
    Collapse,
    IconButton,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Navbar,
    Typography,
} from "@material-tailwind/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import SearchBar from "../SearchBar";
import {AuthContext} from "../AuthProvider";
import HeaderProfile from "./HeaderPorifle";
import {useResponsive} from "../ResponsiveProvider";
import NotificationMenu from "../NotificationsMenu";

function AddAnnouncementMenu() {

    return (
        <Menu>
            <MenuHandler>
                <Typography className='hover:text-blue-500 transition-colors lg:whitespace-nowrap cursor-pointer p-1'
                            as="li"
                            variant="small"
                            color="blue-gray"
                >
                    Ogłoś się
                </Typography>
            </MenuHandler>
            <MenuList>
                <MenuItem>
                    <a href="/dodaj-korepetytor" className="flex items-center hover:text-blue-500 transition-colors">
                        Korepetytor
                    </a>
                </MenuItem>
                <MenuItem>
                    <a href="/dodaj-uczen" className="flex items-center hover:text-blue-500 transition-colors">
                        Uczeń
                    </a>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

function SignedOutLinks() {
    return (
        <>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            >
                <a href='/logowanie'
                   className="flex items-center hover:text-blue-500 transition-colors lg:whitespace-nowrap">
                    Logowanie
                </a>
            </Typography>

            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium md:-ml-6"
            >
                <a href='/rejestracja'
                   className="flex items-center hover:text-blue-500 transition-colors lg:whitespace-nowrap">
                    Rejestracja
                </a>
            </Typography>
        </>
    )
}


function NavList() {
    const {user} = useContext(AuthContext);
    const {isMobile} = useResponsive();

    return (
        <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            >
                <a href="/ogloszenia" className="hover:text-blue-500 transition-colors">
                    Korepetycje
                </a>
            </Typography>

            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            >
                <a href="/ogloszenia/u" className="hover:text-blue-500 transition-colors">
                    Uczniowie
                </a>
            </Typography>

            <AddAnnouncementMenu/>

            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            >
                <a href="/o-serwisie"
                   className="flex items-center hover:text-blue-500 transition-colors lg:whitespace-nowrap">
                    O serwisie
                </a>
            </Typography>

            {user && !isMobile ? <><NotificationMenu /><HeaderProfile /></> : <SignedOutLinks/>}
        </ul>
    );
}

export function Header() {
    const {user} = useContext(AuthContext);
    const [openNav, setOpenNav] = React.useState(false);
    const {isMobile} = useResponsive();

    const handleWindowResize = () =>
        window.innerWidth >= 960 && setOpenNav(false);

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <Navbar className="px-6 py-3 z-50 shadow-none md:shadow" fullWidth={true}>
            <div className="flex items-center justify-between text-blue-gray-900">

                {user && isMobile && (
                    <div className='w-fit flex items-center space-x-1'>
                        <HeaderProfile />
                        <NotificationMenu />
                    </div>

                )}

                <Typography
                    as="a"
                    href="/"
                    variant="h6"
                    className="mr-4 cursor-pointer py-1.5 lg:whitespace-nowrap text-center lg:text-left"
                >
                    { isMobile ? "ZdajTo.pl" : "ZdajTo.pl - Korepetycje od zaraz" }
                </Typography>

                <div className="hidden lg:flex w-full">
                    <div className='w-full flex items-center px-4 min-w-28'>
                        <SearchBar className='min-w-fit max-w-52 mx-auto'/>
                    </div>

                    <NavList/>
                </div>

                <IconButton
                    variant="text"
                    className="h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                    ripple={false}
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav}>
                <NavList/>
            </Collapse>
        </Navbar>
    );
}