import {Button, Card, Checkbox, Dialog, Input, Textarea, Typography} from "@material-tailwind/react";
import api from "../utils/api";
import {useEffect, useState} from "react";
import SpinnerScreen from "../components/SpinnerScreen";
import {Controller, useForm} from "react-hook-form";
import {useResponsive} from "../components/ResponsiveProvider";
import {SearchInput} from "../components/SearchInput";
import {useErrorBoundary} from "react-error-boundary";
import {useParams} from "react-router-dom";

export default function EditAnnouncementTutor() {
    const {showBoundary} = useErrorBoundary();
    const {isMobile} = useResponsive();
    const {id} = useParams();

    const [showDialog, setShowDialog] = useState(false);

    const {control, handleSubmit, register, setError, setValue, formState: {errors, isSubmitting}} = useForm();
    const [data, setData] = useState({
        categories: [],
    });
    const [fetching, setFetching] = useState({
        categories: true,
        announcement: true,
    });

    const textAreaMaxLen = {
        shortDescription: 250,
        description: 2500
    }

    const [textAreaLengths, setTextAreaLengths] = useState({
        shortDescription: 0,
        description: 0
    });

    const [availability, setAvailability] = useState({
        monday: {enabled: false, from: '', to: ''},
        tuesday: {enabled: false, from: '', to: ''},
        wednesday: {enabled: false, from: '', to: ''},
        thursday: {enabled: false, from: '', to: ''},
        friday: {enabled: false, from: '', to: ''},
        saturday: {enabled: false, from: '', to: ''},
        sunday: {enabled: false, from: '', to: ''},
    });

    const dayTranslations = {
        monday: 'Poniedziałek',
        tuesday: 'Wtorek',
        wednesday: 'Środa',
        thursday: 'Czwartek',
        friday: 'Piątek',
        saturday: 'Sobota',
        sunday: 'Niedziela',
    };

    const handleAvailabilityChange = (day, field, value) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                [field]: value
            }
        }));
    };

    const toggleDayAvailability = (day) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                enabled: !prev[day].enabled
            }
        }));
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesRes = await api.get('/categories');
                setData({
                    categories: categoriesRes.data.map(c => ({
                        label: c.category_name,
                        key: c.id
                    }))
                });
                setFetching(prev => ({...prev, categories: false}));
            } catch (e) {
                setFetching(prev => ({...prev, categories: false}));
                showBoundary(e);
            }
        };

        const fetchAnnouncement = async () => {
            try {
                const announcementRes = await api.get(`/tut-announcement/${id}`);
                const announcementData = announcementRes.data;

                // Ustawianie wartości formularza na podstawie pobranych danych
                setValue('lesson-length', announcementData.lesson_length);
                setValue('price', announcementData.price);
                setValue('description', announcementData.description);
                setValue('shortDescription', announcementData.short_description);

                setTextAreaLengths({description: announcementData.description?.length, shortDescription: announcementData.short_description?.length});

                // Ustawianie dostępności
                setAvailability(announcementData.availabilities);

                // Zakres nauczania
                const scopes = {
                    kindergarten: announcementData.education_scopes.includes('Przedszkole'),
                    primarySchool: announcementData.education_scopes.includes('Szkoła podstawowa'),
                    highSchool: announcementData.education_scopes.includes('Szkoła średnia'),
                    collage: announcementData.education_scopes.includes('Studia'),
                };
                setValue('kindergarten', scopes.kindergarten);
                setValue('primarySchool', scopes.primarySchool);
                setValue('highSchool', scopes.highSchool);
                setValue('collage', scopes.collage);

                // Lokalizacje nauczania
                const locations = {
                    tutor: announcementData.teaching_locations.includes('u korepetytora'),
                    student: announcementData.teaching_locations.includes('u ucznia'),
                    online: announcementData.teaching_locations.includes('online'),
                };
                setValue('tutor', locations.tutor);
                setValue('student', locations.student);
                setValue('online', locations.online);

                setFetching(prev => ({...prev, announcement: false}));
            } catch (e) {
                setFetching(prev => ({...prev, announcement: false}));
                showBoundary(e);
            }
        };

        fetchCategories();
        fetchAnnouncement();
    }, [id, setValue, showBoundary]);

    async function submitForm(formData) {
        try {
            const apiData = {
                category_id: formData.category || null,
                city_id: formData.city || null,
                lesson_length: parseInt(formData['lesson-length']),
                price: parseInt(formData.price),
                levels: {
                    kindergarten: formData.kindergarten || false,
                    primarySchool: formData.primarySchool || false,
                    highSchool: formData.highSchool || false,
                    collage: formData.collage || false,
                },
                teaching_locations: {
                    tutor: formData.tutor || false,
                    student: formData.student || false,
                    online: formData.online || false,
                },
                description: formData.description,
                shortDescription: formData.shortDescription,
                availability: availability
            };

            const res = await api.put(`/edit-tut-announcement/${id}`, apiData);
            if (res.status === 200) {
                setShowDialog(true);
            }
        } catch (e) {
            setError('root', {
                message: e.response?.data?.errors?.[0] || 'Wystąpił błąd podczas edycji ogłoszenia.',
            });
        }
    }

    if (fetching.categories || fetching.announcement) {
        return <SpinnerScreen/>;
    }

    return (
        <>
            {showDialog && (
                <Dialog open={true}>
                    <div className='p-8'>
                        <Typography variant='h3'>
                            Sukces! Twoje ogłoszenie zostało zaktualizowane!
                        </Typography>
                        <Button variant="gradient" color="green" onClick={() => window.location.replace('/')}
                                className='mt-6 self-center'>
                            <span>Strona główna</span>
                        </Button>
                    </div>
                </Dialog>
            )}
            <div className="flex justify-center w-full">
                <Card className="w-full md:w-4/5 lg:w-1/2 p-10 my-6 flex flex-col gap-4 md:shadow-xl"
                      shadow={!isMobile}>

                    <Typography variant='h3' className='text-center mb-2'>
                        Edytuj ogłoszenie korepetytora
                    </Typography>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 font-bold">
                            Przedmiot
                        </Typography>
                        <Controller
                            name="category"
                            control={control}
                            rules={{required: 'Wybór przedmiotu jest wymagany.'}}
                            render={({field}) => (
                                <>
                                    <SearchInput value={field.value} onChange={field.onChange}
                                                 onBlur={field.onBlur} data={data.categories}/>
                                    {errors.category &&
                                        <div
                                            className='add-tutor-announcement-form-err'>{errors.category.message}
                                        </div>
                                    }
                                </>
                            )}
                        />
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Miasto
                        </Typography>
                        <Controller
                            name="city"
                            control={control}
                            rules={{required: 'Miasto jest wymagane.'}}
                            render={({field}) => (
                                <>
                                    <SearchInput minCharacters={3} value={field.value} onChange={field.onChange}
                                                 onBlur={field.onBlur} fetcher={async (inputValue) => {
                                        if (inputValue.length < 3) return [];
                                        const res = await api.get(`/cities/${inputValue}`);
                                        return res.data.map(d => ({
                                            label: d.city,
                                            key: d.id,
                                            subLabel: d.state
                                        }));
                                    }}/>
                                    {errors.city &&
                                        <div className='add-tutor-announcement-form-err'>{errors.city.message}</div>}
                                </>
                            )}
                        />
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Długość lekcji (w minutach)
                        </Typography>
                        <Input
                            type="number"
                            {...register('lesson-length', {
                                required: 'Długość lekcji jest wymagana.',
                                min: {value: 15, message: 'Minimalna długość lekcji to 15 minut.'},
                                max: {value: 180, message: 'Maksymalna długość lekcji to 180 minut.'}
                            })}
                        />
                        {errors['lesson-length'] && (
                            <div className='add-tutor-announcement-form-err'>{errors['lesson-length'].message}</div>
                        )}
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Cena (zł za godzinę)
                        </Typography>
                        <Input
                            type="number"
                            {...register('price', {
                                required: 'Cena jest wymagana.',
                                min: {value: 0, message: 'Cena nie może być mniejsza niż 0.'}
                            })}
                        />
                        {errors.price && (
                            <div className='add-tutor-announcement-form-err'>{errors.price.message}</div>
                        )}
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Pełny opis
                        </Typography>
                        <Textarea
                            {...register('description', {
                                required: 'Pełny opis jest wymagany.',
                                onChange: (e) => setTextAreaLengths((p) => ({...p, description: e.target.value.length}))
                            })}
                        />
                        {errors.description && (
                            <div className='add-tutor-announcement-form-err'>{errors.description.message}</div>
                        )}
                    </div>
                    <Typography
                        className='text-xs -mt-3 ml-auto'>{textAreaLengths.description} /{textAreaMaxLen.description}</Typography>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Krótki opis (maks. 250 znaków)
                        </Typography>
                        <Textarea
                            {...register('shortDescription', {
                                required: 'Krótki opis jest wymagany.',
                                maxLength: {value: 250, message: 'Krótki opis może mieć maksymalnie 200 znaków.'},
                                onChange: (e) => setTextAreaLengths((p) => ({...p, shortDescription: e.target.value.length}))
                            })}
                        />
                        {errors.shortDescription && (
                            <div className='add-tutor-announcement-form-err'>{errors.shortDescription.message}</div>
                        )}
                    </div>
                    <Typography
                        className='text-xs -mt-3 ml-auto'>{textAreaLengths.shortDescription} /{textAreaMaxLen.shortDescription}</Typography>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Zakres nauczania
                        </Typography>
                        <div className="flex flex-col gap-2">
                            <Checkbox {...register('kindergarten')} label="Przedszkole"/>
                            <Checkbox {...register('primarySchool')} label="Szkoła podstawowa"/>
                            <Checkbox {...register('highSchool')} label="Szkoła średnia"/>
                            <Checkbox {...register('collage')} label="Studia"/>
                        </div>
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Lokalizacja nauczania
                        </Typography>
                        <div className="flex flex-col gap-2">
                            <Checkbox {...register('tutor')} label="U korepetytora"/>
                            <Checkbox {...register('student')} label="U ucznia"/>
                            <Checkbox {...register('online')} label="Online"/>
                        </div>
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Dostępność
                        </Typography>
                        {Object.keys(availability).map((day) => (
                            <div key={day} className="flex items-center gap-2">
                                <Checkbox
                                    checked={availability[day].enabled}
                                    onChange={() => toggleDayAvailability(day)}
                                    label={dayTranslations[day]}
                                />
                                {availability[day].enabled && (
                                    <div className="flex gap-2">
                                        <Input
                                            type="time"
                                            value={availability[day].from}
                                            onChange={(e) => handleAvailabilityChange(day, 'from', e.target.value)}
                                        />
                                        <Input
                                            type="time"
                                            value={availability[day].to}
                                            onChange={(e) => handleAvailabilityChange(day, 'to', e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>


                    <Button disabled={isSubmitting} className="my-3" onClick={handleSubmit(submitForm)}>
                        {isSubmitting ? 'Zapisywanie zmian...' : 'Zapisz zmiany'}
                    </Button>
                </Card>
            </div>
        </>
    );
}
