import React, { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
    const { user } = useContext(AuthContext);

    // Jeśli użytkownik nie jest zalogowany, przekieruj na stronę logowania
    if (!user) {
        return <Navigate to="/logowanie" />;
    }

    // Jeśli użytkownik jest zalogowany, renderuj dzieci (Outlet dla zagnieżdżonych tras)
    return <Outlet />;
};

export default ProtectedRoutes;
