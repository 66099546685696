import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import {ThemeProvider} from "@material-tailwind/react";
import AuthProvider from "./components/AuthProvider";
import {ResponsiveProvider} from "./components/ResponsiveProvider";
import NotificationsProvider from "./components/NotificationsProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <AuthProvider>
                <NotificationsProvider>
                    <ResponsiveProvider>
                        <App/>
                    </ResponsiveProvider>
                </NotificationsProvider>
            </AuthProvider>
        </ThemeProvider>
    </React.StrictMode>,
);
