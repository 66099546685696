import {Rating, Typography} from "@material-tailwind/react";
import {createApiLink} from "../utils/functions";

export default function Opinion({userId, img, firstname, lastname, opinion, rate, className =''}) {
    return (
        <div className={`flex flex-row p-2 border-t shadow-md ${className}`}>
            <a href={`/profil/${userId}`}>
                <img
                    className="h-14 w-14 rounded-full object-cover object-center"
                    src={createApiLink(img)}
                />
            </a>
            <div className='px-3 w-full'>
                <div className='flex flex-col md:flex-row'>
                    <Typography variant='lead' className='font-bold hover:text-blue-300 hover:underline transition-all' as='a' href={`/profil/${userId}`}>
                        {firstname} {lastname}
                    </Typography>

                    <Rating value={rate} readonly className='md:ml-auto'/>
                </div>

                <Typography className='p-1'>
                    {opinion}
                </Typography>
            </div>
        </div>
    )
}