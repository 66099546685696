import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Textarea} from "@material-tailwind/react";
import {useContext} from "react";
import {useForm} from "react-hook-form";
import {useAlert} from "./DisimisibleAlert";
import api from "../utils/api";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./AuthProvider";

export default function NewConversationDialog({name, open, handleOpen, receiverId}) {
    const {register, handleSubmit, setError, formState: {errors}} = useForm();
    const showAlert = useAlert();
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const userId = user.id;

    const onSubmit = async (data) => {
        try {
            const res = await api.post('/conversation', {...data, user_id_1: userId, user_id_2: receiverId});

            navigate(`/konwersacje/${res.data.conversationId}`);
        } catch (e) {
            console.log(e);
            const res = e?.response?.data;
            if (res) {
                setError('root', {
                    message: res.errors[0],
                })
            } else {
                handleOpen();
                showAlert('Wystąpił nieoczekiwany błąd podczas wysyłania wiadomości.');
            }
        }
    }

    return (
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>Napisz wiadomość do {name}</DialogHeader>
            <DialogBody>
                <Input
                    label='Temat wiadomości'
                    {...register('topic', {required: true})}
                    containerProps={{className: 'mb-2'}}
                />
                <Textarea
                    label='Wiadomość'
                    {...register('message', {required: true})}
                />

                {errors.root && (
                    <div className='auth-form-err'>{errors.root.message}</div>
                )}
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    Anuluj
                </Button>
                <Button variant="gradient" color="green" onClick={handleSubmit(onSubmit)}>
                    Wyślij
                </Button>
            </DialogFooter>
        </Dialog>
    )
}