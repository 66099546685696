import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader, Option,
    Rating, Select,
    Textarea,
    Typography
} from "@material-tailwind/react";
import Divider from "./Divider";
import Opinion from "./Opinion";
import {useContext, useEffect, useRef, useState} from "react";
import api from "../utils/api";
import {AuthContext} from "./AuthProvider";
import {useNavigate, useParams} from "react-router-dom";
import useGetData from "../utils/useGetData";
import Link from "./Link";

function AddOpinionDialog({open, handler, userId}) {
    const navigate = useNavigate();

    const [opinion, setOpinion] = useState("");
    const [rating, setRating] = useState(0);
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {user} = useContext(AuthContext);

    const handleRatingChange = (value) => {
        setRating(value);
    };

    const handleOpinionChange = (value) => {
        setOpinion(value.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Walidacja - sprawdzamy, czy opinia nie jest pusta
        if (opinion.trim() === "") {
            setError("Opinia nie może być pusta.");
            return;
        }
        if (opinion.length > 250) {
            setError("Opinia zbyt długa.");
            return;
        }
        if (rating === 0) {
            setError("Należy wybrać ocene korepetytora");
            return;
        }

        setError("");
        setIsSubmitting(true);

        try {
            await api.post(`/review`,{rate: rating, content: opinion, user_id: userId});

            handler();
            navigate(0);
        } catch (error) {
            if(error.response?.data?.errors)
                setError(error.response.data.errors[0]);
            else
                setError('Cos poszlo nie tak');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={open} handler={handler}>
            <DialogHeader>Podziel się opinią o korepetytorze</DialogHeader>
            <DialogBody className='w-full px-8'>
                <Typography variant='lead' className='font-bold'>
                    Ocena korepetytora:
                </Typography>
                <Rating className='ml-2' value={null} onChange={handleRatingChange}/>

                <Typography variant='lead' className='font-bold'>
                    Opinia:
                </Typography>
                <Textarea label='Opisz tu zalety lub wady korepetytora' value={opinion} onChange={handleOpinionChange}/>

                <Typography className='add-tutor-announcement-form-err'>
                    {error}
                </Typography>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handler}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
                <Button variant="gradient" color="light-blue" onClick={handleSubmit} disabled={isSubmitting}>
                    <span>Dodaj opinie</span>
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

export function OpinionSection({id, showAddOpinion = true}) {
    const {user} = useContext(AuthContext);
    const [reviewFormOpen, setReviewFormOpen] = useState(false);
    const limit = useRef(5);
    const order = useRef('desc');
    const [url, setUrl] = useState(`/profile/${id}/reviews/${limit.current}/${order.current}`);
    const [reviews, setReviews] = useState([]);


    const handleOpen = () => setReviewFormOpen(!reviewFormOpen);
    let reviewsTemp;
    reviewsTemp = useGetData(url);

    useEffect(() => {
        setReviews(reviewsTemp);
    }, [reviewsTemp]);

    showAddOpinion = showAddOpinion && user;

    return (
        <>
            {showAddOpinion && <AddOpinionDialog handler={handleOpen} open={reviewFormOpen} userId={id}/>}
            <div className='my-4'>
                <div className='max-w-full flex justify-between px-2'>
                    <Typography variant='lead' className='font-bold'>
                        Opinie
                    </Typography>

                    {showAddOpinion && (
                        <Button variant='outlined' size='sm' color='blue' onClick={handleOpen}>
                            Dodaj opinie
                        </Button>
                    )}

                    {!user && (
                        <Typography>
                            Aby dodać opinie
                            <Link href='/logowanie' blank={false}>
                                zaloguj się
                            </Link>
                        </Typography>
                    )}
                </div>

                <Divider/>

                {reviews?.length > 0 ? (
                    <ul className='list-none'>
                        <Select onChange={(v) => {
                            order.current = v;
                            setUrl(`/profile/${id}/reviews/${limit.current}/${order.current}`);
                        }} label='Sortowanie'>
                            <Option value='desc'>Od najelpszych</Option>
                            <Option value='asc'>Od najgorszych</Option>
                        </Select>

                        {reviews.map((review) => (
                            <li key={review.id} className=' border-gray-300 py-1'>
                                <Opinion
                                    userId={review.reviewer_id}
                                    img={review.profile_image}
                                    firstname={review.firstname}
                                    lastname={review.lastname}
                                    rate={review.rate}
                                    opinion={review.content}
                                />
                            </li>
                        ))}
                        <div className='flex justify-center w-full'>
                            <Button size='sm' variant='outlined' className='mt-1 w-[50%]' onClick={() => {
                                limit.current += 5;
                                setUrl(`/profile/${id}/reviews/${limit.current}/${order.current}`);
                            }}>
                                Więcej opini
                            </Button>
                        </div>
                    </ul>
                ) : (
                    <Typography className='px-2'>Brak opinii</Typography>
                )}
            </div>
        </>
    );
}