import {
    Button,
    Checkbox,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input,
    Option,
    Select, ThemeProvider,
    Typography
} from "@material-tailwind/react";
import { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import NestingAccordion from "../../NestingAccordion";
import { SearchInput } from "../../SearchInput";
import { AnnouncementDataContext } from "../../../pages/StudentAnnouncements";
import { useStudentFilters } from "./StudentFiltersFormProvider";
import { useResponsive } from "../../ResponsiveProvider";
import {cityFetcher, filtersThemeObject} from "../announcements/Filters";
import {states} from "../../../utils/constants";
import {createPortal} from "react-dom";

function FiltersComponents({ className, setOpen }) {
    const {isMobile} = useResponsive();
    const portalRoot = document.getElementById('filters-portal');
    const data = useContext(AnnouncementDataContext);
    const { register, control, handleSubmit, resetFilters, commitFilter, onSubmit, setIsSearchInputActive } = useStudentFilters();
    const categories = data?.categories?.map((c) => ({ label: c.category_name, value: c.id }));

    return (
        <ThemeProvider value={filtersThemeObject}>
        <div className={`w-full ${className}`}>
            <NestingAccordion header='Przedmiot' open={true} className='mt-4'>
                <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                        <SearchInput
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={'Wyszukaj przedmiot...'}
                            data={categories || []}
                            onBlur={(e) => {
                                field.onBlur();
                                commitFilter(false);
                            }}
                            onFocus={() => setIsSearchInputActive(true)}
                        />
                    )}
                />
            </NestingAccordion>

            <NestingAccordion header='Lokalizacja' open={true}>
                <Typography className='font-semibold text-blue-gray-500 ml-1 text-sm'>
                    Miasto
                </Typography>
                <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                        <SearchInput
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={'Wyszukaj miasto...'}
                            fetcher={cityFetcher}
                            onBlur={() => commitFilter(false)}
                            onFocus={() => commitFilter(true)}
                        />
                    )}
                />

                <Controller
                    name="state"
                    control={control}
                    render={({field}) => (
                        <Select label="Województwo" onChange={field.onChange} value={field.value} containerProps={{className: 'mt-4'}}>
                            {states.map(s => {
                                return <Option value={s.value}>{s.state}</Option>
                            })}
                        </Select>
                    )}
                />
            </NestingAccordion>

            <NestingAccordion header='Cena' open={true}>
                <Input
                    variant='static'
                    label='Od'
                    placeholder='Cena w zł'
                    {...register('price_from')}
                    onBlur={() => commitFilter(false)}
                    onFocus={() => commitFilter(true)}
                />
                <Input
                    variant='static'
                    label='Do'
                    placeholder='Cena w zł'
                    {...register('price_to')}
                    onBlur={() => commitFilter(false)}
                    onFocus={() => commitFilter(true)}
                />
            </NestingAccordion>

            <NestingAccordion header='Forma zajęć' open={true}>
                <Checkbox label='U korepetytora' {...register('location.tutor')} />
                <Checkbox label='U ucznia' {...register('location.student')} />
                <Checkbox label='Zdalnie' {...register('location.online')} />
            </NestingAccordion>

            <NestingAccordion header='Zakres edukacji' open={true}>
                <Checkbox label='Przedszkole' {...register('scope.kindergarten')} />
                <Checkbox label='Szkoła podstawowa' {...register('scope.primary_school')} />
                <Checkbox label='Szkoła średnia' {...register('scope.high_school')} />
                <Checkbox label='Studia' {...register('scope.college')} />
            </NestingAccordion>

            <NestingAccordion header='Długość lekcji' open={false}>
                <Input
                    variant='static'
                    label='Od'
                    placeholder='Długość - minuty'
                    {...register('lesson_len_from')}
                    onBlur={() => commitFilter(false)}
                    onFocus={() => commitFilter(true)}
                />
                <Input
                    variant='static'
                    label='Do'
                    placeholder='Długość - minuty'
                    {...register('lesson_len_to')}
                    onBlur={() => commitFilter(false)}
                    onFocus={() => commitFilter(true)}
                />
            </NestingAccordion>

            <NestingAccordion header='Pozostałe' open={true}>
                <Input
                    variant='static'
                    label='Ilość ogłoszeń na stronie'
                    placeholder='10'
                    {...register('limit')}
                    onBlur={() => commitFilter(false)}
                    onFocus={() => commitFilter(true)}
                />
                <Checkbox label='Z numerem telefonu' {...register('has_phone')} />
                <Checkbox label='Ze zdjęciem' {...register('has_image')} />
                <Checkbox label='Z emailem' {...register('has_mail')} />
            </NestingAccordion>

            {isMobile ? (
                portalRoot && createPortal(
                    <>
                        {/*filter*/}
                        <Button onClick={() => {
                            handleSubmit(onSubmit);
                            setOpen(false);
                        }}>
                            Filtruj
                        </Button>

                        {/*clear*/}
                        <Button onClick={() => {
                            resetFilters();
                            setOpen(false);
                        }} variant={"outlined"} className='ml-3'>
                            Wyczyść
                        </Button>
                    </>,
                    portalRoot
                )
            ) : (
                <>
                    <Button onClick={handleSubmit(onSubmit)}>Filtruj</Button>
                    <Button onClick={resetFilters} variant={"outlined"} className='ml-3'>Wyczyść</Button>
                </>
            )}
        </div>
        </ThemeProvider>
    );
}

export default function StudentFilters() {
    const [open, setOpen] = useState(false);
    const { isMobile } = useResponsive();

    const handleOpen = () => setOpen(!open);

    // mobile
    if (isMobile) {
        return (
            <div className='w-full'>
                <Button onClick={handleOpen} variant="outlined" className='my-1' fullWidth>
                    Filtruj ogłoszenia
                </Button>

                <Dialog open={open} handler={handleOpen} className='h-[95vh]'>
                    <DialogHeader className='h-1/6'>Dostosuj ogłoszenia według potrzeb</DialogHeader>
                    <DialogBody className='h-4/6 overflow-scroll'>
                        <FiltersComponents setOpen={setOpen} />
                    </DialogBody>
                    <DialogFooter className='h-1/6 justify-center' id='filters-portal' />
                </Dialog>
            </div>
        );
    }

    // large screen
    return (
        <div className='min-w-72 max-w-80'>
            <Typography variant='h4' className='h-14 flex items-center'>Filtruj ogłoszenia uczniów</Typography>
            <hr className='my-1 bg-blue-gray-800 h-1' />
            <FiltersComponents className='ml-1' />
        </div>
    );
}
