import React, {useContext, useState} from 'react';
import {Button, Card, Typography} from "@material-tailwind/react";
import Divider from "../components/Divider";
import useGetData from "../utils/useGetData";
import {useParams} from "react-router-dom";
import UserAnnouncementsPanel from "../components/pageParts/profile/UserAnnouncements";
import {OpinionSection} from "../components/OpinionSection";
import NewConversationDialog from "../components/NewConversationDialog";
import {AuthContext} from "../components/AuthProvider";
import {useAlert} from "../components/DisimisibleAlert";
import {checkUserActivity, createApiLink} from "../utils/functions";

function UserProfile() {
    const {user} = useContext(AuthContext);
    const showAlert = useAlert();
    const {id} = useParams();
    const userData = useGetData(`/profile/${id}`);
    const [openConversationDialog, setOpenConversationDialog] = useState(false);
    const handleConversationDialog = () => {
        setOpenConversationDialog(p => !p);
    }

    const handleWriteMessage = async () => {
        if (!user) {
            showAlert('Aby napisać wiadomość do korepetyrora, wpierw zaloguj się.');
        } else {
            handleConversationDialog();
        }
    }

    return (
        <>
            {user && <NewConversationDialog name={`${userData?.firstname} ${userData?.lastname}`} receiverId={userData?.id} open={openConversationDialog} handleOpen={handleConversationDialog} />}
            <Card className='w-full md:w-4/5 flex flex-col self-center my-4 px-4 py-4 md:px-6 md:py-6' shadow={false}>
                <div className='flex flex-col items-center w-full'>
                    {/* Zdjęcie profilowe */}
                    <img
                        className="h-32 w-32 rounded-full object-cover mb-4"
                        src={createApiLink(userData?.profile_image)} //todo poprawic localhosta na produkcji
                        alt="Profile"
                    />
                    {/* Nazwa użytkownika */}
                    <Typography variant='h4' className='font-bold text-center'>
                        {userData?.firstname} {userData?.lastname}
                    </Typography>
                    {/* Ostatnia aktywność */}
                    { userData?.last_login && (
                        <Typography variant='small' className='text-gray-500'>
                            Ostatnio aktywny: { checkUserActivity(userData?.last_login) }
                        </Typography>
                    ) }

                    {/* Data założenia konta */}
                    <Typography variant='small' className='text-gray-500'>
                        Konto założone: {new Date(userData?.created_at).toLocaleDateString()}
                    </Typography>

                    <Button className='mt-1 mb-2' variant='gradient' color='blue'
                            onClick={handleWriteMessage}>
                        <div className='flex items-center justify-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="size-6">
                                <path
                                    d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z"/>
                                <path
                                    d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z"/>
                            </svg>

                            Napisz wiadomość
                        </div>
                    </Button>
                </div>

                {/* Opis użytkownika */}
                <Divider className='my-4'/>
                <Typography variant='lead' className='font-bold px-2'>
                    Opis
                </Typography>
                {userData?.profile_description ? (
                        <Typography variant='paragraph' className='my-2 mx-4'>
                            {userData?.profile_description}
                        </Typography>
                    ) :
                    (
                        <Typography variant='paragraph' className='my-2 mx-4'>
                            Brak opisu.
                        </Typography>
                    )}

                <Divider className='my-4'/>

                {/* Dane kontaktowe */}
                <Typography variant='lead' className='font-bold px-2'>
                    Dane kontaktowe
                </Typography>
                <Typography variant='paragraph' className='px-5 w-full'>
                    <div className='flex gap-2 items-center w-full break-words'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                        </svg>

                        {userData?.show_email ? (
                            userData?.email
                        ) : (
                            "Nie podano."
                        )}
                    </div>
                    <div className='flex gap-2 items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth={1.5}
                             stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                        </svg>
                        {userData?.show_phone ? (
                            userData?.profile_phone
                        ) : (
                            "Nie podano."
                        )}
                        {/*{ todo ikonki nieresponsywne }*/}
                    </div>
                </Typography>

                <Divider className='my-4'/>

                {/* Informacje o edukacji */}
                <Typography variant='lead' className='font-bold px-2'>
                    Edukacja
                </Typography>
                <Typography variant='paragraph' className='px-5'>
                    {userData?.profile_school && userData?.profile_education_city ? (
                        userData?.profile_school + " " + userData?.profile_education_city
                    ) : "Nie podano."}

                </Typography>

                <Divider className='my-4'/>

                {/* Ogłoszenia użytkownika */}
                <Typography variant='lead' className='font-bold px-2'>
                    Ogłoszenia korepetycji
                </Typography>

                <div className='ml-3'>
                    <UserAnnouncementsPanel url={`/profile/${id}/tutor`}/>
                </div>

                <Divider className='my-4'/>

                {/* Ogłoszenia o poszukiwaniu korepetytora */}
                <Typography variant='lead' className='font-bold px-2'>
                    Ogłoszenia o poszukiwaniu korepetytora
                </Typography>
                <div className='ml-3'>
                    <UserAnnouncementsPanel url={`/profile/${id}/student`} isStudent/>
                </div>

                <div className='ml-3'>
                    <OpinionSection id={id} />
                </div>
            </Card>
        </>
    );
}

export default UserProfile;
