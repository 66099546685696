import {Suspense, useContext} from "react";
import {AuthContext} from "../components/AuthProvider";
import {useParams} from "react-router-dom";
import MyProfile from "./MyProfile";
import UserProfile from "./UserProfile";
import {DefaultSkeletonPage} from "../components/Skeletons";

export default function Profile() {
    const {user} = useContext(AuthContext);
    const {id} = useParams();

    if (user?.id === parseInt(id)) {
        return <Suspense fallback={<DefaultSkeletonPage />}> <MyProfile /> </Suspense>
    }

    return <UserProfile />
}