import React from 'react';
import {CardSkeleton, DividerSkeleton, ImageSkeleton, TextSkeleton} from "../components/Skeletons";



// Szkielet lewej strony ogłoszenia
function LeftTutorAnnouncementSkeleton() {
    return (
        <div className='w-full md:w-72 flex flex-col'>
            <CardSkeleton>
                <div className='flex w-full justify-center bg-gray-300 py-3 px-1 rounded-sm'>
                    <div className='w-fit'>
                        <ImageSkeleton className="h-48 w-48" />
                        <div className='mt-2 text-center'>
                            <TextSkeleton className="h-4 w-32 mb-2" />
                            <TextSkeleton className="h-3 w-24" />
                        </div>
                    </div>
                </div>
            </CardSkeleton>

            <DividerSkeleton />

            <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />

            <DividerSkeleton />

            <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />

            <DividerSkeleton />

            <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />

            <DividerSkeleton />

            <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />
        </div>
    );
}

// Szkielet prawej strony ogłoszenia
function RightTutorAnnouncementSkeleton() {
    return (
        <CardSkeleton>
            <div className='w-full md:px-3 mt-4'>
                <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
                <DividerSkeleton />

                <div className="space-y-4 px-3">
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                </div>

                <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
                <DividerSkeleton />

                <TextSkeleton className='h-3 w-full px-3 mb-2' />

                <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2 text-center' />
                <DividerSkeleton />

                {/* Skeleton for opinions */}
                <div className='flex flex-row p-2 bg-gray-100 my-2 mx-2 animate-pulse'>
                    <ImageSkeleton className="h-14 w-14" />
                    <div className='px-3'>
                        <TextSkeleton className='h-4 w-28 mb-1' />
                        <TextSkeleton className='h-3 w-60' />
                    </div>
                </div>
                <div className='flex flex-row p-2 bg-gray-100 my-2 mx-2 animate-pulse'>
                    <ImageSkeleton className="h-14 w-14" />
                    <div className='px-3'>
                        <TextSkeleton className='h-4 w-28 mb-1' />
                        <TextSkeleton className='h-3 w-60' />
                    </div>
                </div>
                <div className='flex flex-row p-2 bg-gray-100 my-2 mx-2 animate-pulse'>
                    <ImageSkeleton className="h-14 w-14" />
                    <div className='px-3'>
                        <TextSkeleton className='h-4 w-28 mb-1' />
                        <TextSkeleton className='h-3 w-60' />
                    </div>
                </div>

            </div>
        </CardSkeleton>
    );
}

// Szkielet dla całego ogłoszenia
function TutorAnnouncementSkeleton() {
    return (
        <CardSkeleton>
            <div className='w-full flex flex-col md:flex-row self-center my-4 px-1 py-1 md:px-6 md:py-3'>
                <LeftTutorAnnouncementSkeleton />
                <RightTutorAnnouncementSkeleton />
            </div>
        </CardSkeleton>
    );
}

export default TutorAnnouncementSkeleton;
