import HomeAbout from "../components/pageParts/home/HomeAbout";
import HomeSponsored from "../components/pageParts/home/HomeSponsored";
import {PopularCategoryCards} from "../components/pageParts/home/HomePopular";
import {Suspense} from "react";
import {Typography} from "@material-tailwind/react";
import HomePopularCities from "../components/pageParts/home/HomePopularCities";
import HomeNewestAnnouncements from "../components/pageParts/home/HomeNewestAnnouncements";
import {TextSkeleton} from "../components/Skeletons";

export default function Home() {
    return (
        <div className='px-4 pb-3'>

            <HomeAbout/>

            <Suspense fallback={
                <TextSkeleton>
                    <Typography variant={'h4'} className='text-center'>Najnowsze wyróżnione ogłoszenia</Typography>
                </TextSkeleton>
            }>
                    <HomeSponsored/>
            </Suspense>

            <Suspense fallback={
                <TextSkeleton>
                    <Typography variant='h4' className='text-center'>Popularne Kategorie</Typography>
                </TextSkeleton>
            }>
                <PopularCategoryCards/>
            </Suspense>

            <Suspense fallback={
                <TextSkeleton>
                    <Typography variant={'h4'} className='text-center'>Najpopularniejsze miasta</Typography>
                </TextSkeleton>
            }>
                <HomePopularCities/>
            </Suspense>

            <Suspense fallback={
                <TextSkeleton>
                    <Typography variant={'h4'} className='text-center'>Najnowsze ogłoszenia</Typography>
                </TextSkeleton>
            }>
                <HomeNewestAnnouncements/>
            </Suspense>

        </div>
    )
}