// src/AuthContext.js
import React, {createContext, useEffect, useState} from 'react';
import api from "../utils/api";


export const AuthContext = createContext();

export default function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    let [serverResponded, setServerResponded] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/me');
                setUser(response.data);
                setServerResponded(true);
            } catch (err) {
                console.error("Failed to fetch user:", err);
                setServerResponded(true);
            }
        };

        fetchUser();
    }, []);

    if (serverResponded === true) {
        return (
            <AuthContext.Provider value={{user, setUser}}>
                {children}
            </AuthContext.Provider>
        );
    }
};
