// Ogólny szkielet dla karty
export function CardSkeleton({ children, className }) {
    return (
        <div className={`bg-gray-50 rounded-lg overflow-hidden w-full h-full shadow-md animate-pulse ${className}`}>
            {children}
        </div>
    );
}

// Szkielet dla obrazów (z możliwością ustawienia proporcji)
export function ImageSkeleton({ className, aspectRatio = '1/1' }) {
    return (
        <div className={`animate-pulse bg-gray-200 ${className}`} style={{ aspectRatio }}>
            <div className="bg-gray-200 h-full w-full rounded-md" />
        </div>
    );
}

// Szkielet dla tekstu (z opcjonalnym ustawieniem szerokości i wysokości)
export function TextSkeleton({ className, width = 'full', height = '4' }) {
    return (
        <div className={`animate-pulse ${className}`}>
            <div className={`bg-gray-200 h-${height} w-${width} rounded-md`} />
        </div>
    );
}

// Szkielet dla separatorów
export function DividerSkeleton({ className, thickness = '1', color = 'gray-200' }) {
    return (
        <div className={`bg-${color} h-${thickness} w-full my-4 ${className}`} />
    );
}

// Uniwersalny szkielet dla całej strony (można modyfikować sekcje)
export function DefaultSkeletonPage() {
    return (
        <div className="w-full p-16 space-y-6">
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <ImageSkeleton className="w-24 h-24 rounded-full"/>
                <div className="space-y-2 flex-1">
                    <TextSkeleton className="h-8 w-1/4" height={5}/>
                    <TextSkeleton className="h-6 w-10/12"/>
                    <TextSkeleton className="h-6 w-3/4"/>
                </div>
            </div>

            <DividerSkeleton/>


            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <CardSkeleton>
                    <div className="p-4 space-y-4">
                        <ImageSkeleton className="h-36 w-full rounded-md"/>
                        <TextSkeleton className="h-6 w-3/4"/>
                        <TextSkeleton className="h-6 w-2/4"/>
                    </div>
                </CardSkeleton>
                <CardSkeleton>
                    <div className="p-4 space-y-4">
                        <ImageSkeleton className="h-36 w-full rounded-md"/>
                        <TextSkeleton className="h-6 w-3/4"/>
                        <TextSkeleton className="h-6 w-2/4"/>
                    </div>
                </CardSkeleton>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <CardSkeleton>
                    <div className="p-4 space-y-4">
                        <ImageSkeleton className="h-36 w-full rounded-md"/>
                        <TextSkeleton className="h-6 w-3/4"/>
                        <TextSkeleton className="h-6 w-2/4"/>
                    </div>
                </CardSkeleton>
                <CardSkeleton>
                    <div className="p-4 space-y-4">
                        <ImageSkeleton className="h-36 w-full rounded-md"/>
                        <TextSkeleton className="h-6 w-3/4"/>
                        <TextSkeleton className="h-6 w-2/4"/>
                    </div>
                </CardSkeleton>
            </div>
        </div>
    );
}
