import {createContext, Suspense} from "react";
import {useSearchParams} from "react-router-dom";
import StudentFilters from "../components/pageParts/studentAnnouncements/StudentFilters";
import StudentAnnouncementsComp from "../components/pageParts/studentAnnouncements/StudentAnnouncementsComp";
import useGetData from "../utils/useGetData";
import {StudentFiltersFormProvider} from "../components/pageParts/studentAnnouncements/StudentFiltersFormProvider";
import {DefaultSkeletonPage} from "../components/Skeletons";
import {createApiLink} from "../utils/functions";

export const AnnouncementDataContext = createContext(
    {states: null, categories: null, announcements: []}
);

function DataProvider({children}) {
    let [searchParams, setSearchParams] = useSearchParams();

    const categories = useGetData(createApiLink('/categories'));
    const announcementsData = useGetData(createApiLink(`/student-announcements/filter?${searchParams || ''}`));

    return (
        <AnnouncementDataContext.Provider
            value={{categories, announcementsData}}>
            {children}
        </AnnouncementDataContext.Provider>
    );
}

export default function StudentAnnouncements() {
    return (
        <Suspense fallback={<DefaultSkeletonPage />}>
            <DataProvider>
                <StudentFiltersFormProvider>
                    <div className='px-3 py-4 md:py-1 max-w-full flex flex-col md:flex-row'>
                        <StudentFilters/>
                        <StudentAnnouncementsComp/>
                    </div>
                </StudentFiltersFormProvider>
            </DataProvider>
        </Suspense>
    );
}
