import {IconButton, Typography} from "@material-tailwind/react";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";

export function Pagination({pages, setPage, currentPage}) {
    const [active, setActive] = useState(currentPage);

    const next = () => {
        if (active === pages) return;

        setActive(active + 1);
    };

    const prev = () => {
        if (active === 1) return;

        setActive(active - 1);
    };

    useEffect(() => {
        setPage('page', active)
    }, [active]);

    useEffect(() => {
        setActive(currentPage);
    }, [currentPage]);

    return (
        <div className="flex items-center gap-8 select-none">
            <IconButton
                size="sm"
                variant="outlined"
                onClick={prev}
                disabled={active === 1}
            >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
                Strona <strong className="text-gray-900">{active}</strong> z{" "}
                <strong className="text-gray-900">{pages}</strong>
            </Typography>
            <IconButton
                size="sm"
                variant="outlined"
                onClick={next}
                disabled={active === pages}
            >
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
        </div>
    );
}