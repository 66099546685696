import {IconButton, Typography} from "@material-tailwind/react";
import {useResponsive} from "../../ResponsiveProvider";
import {useNavigate} from "react-router-dom";

export function ConversationHeader({ topic, boxName }) {
    const {isMobile} = useResponsive();
    const navigate = useNavigate();

    return (
        <div className="mx-4 border-b border-blue-gray-100 h-[10%] flex space-x-3 items-center">
            {isMobile && (
                <IconButton variant='text' onClick={() => navigate('/konwersacje')} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="size-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                    </svg>
                </IconButton>
            )}

            <div>
                <Typography className="text-xl font-bold">
                    {topic || "Wybierz konwersacje"}
                </Typography>
                <Typography className="text-sm">
                    {boxName || "Na razie jest tu pusto..."}
                </Typography>
            </div>
        </div>
    );
}
