import {useEffect, useState} from "react";
import {Button, Dialog, DialogFooter, DialogHeader} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import api from "../utils/api";

export default function Logout() {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({open: false, header: ''});

    useEffect(() => {
        async function logout() {
            try {
                const res = await api.get('/sign-out');

                if (res.status === 200) {
                    setDialog({open: true, header: 'Pomyślnie wylogowano'});
                }
            } catch (e) {
                setDialog({open: true, header: 'Nie można wylogować'});
            }
        }

        logout();
    }, []);

    if (dialog.open) {
        return (
            <Dialog open={true}>
                <DialogHeader>
                    {dialog.header}
                </DialogHeader>
                <DialogFooter>
                    <Button onClick={() => {
                        navigate('/');
                        navigate(0);
                    }}>Ok</Button>
                </DialogFooter>
            </Dialog>
        )
    } else {
    return null;
    }

}