import React from 'react';

const Regulations = () => {
    return (
        <div className="max-w-5xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold text-center mb-6">Regulamin Serwisu Zdaj-To.pl</h1>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">§1. Postanowienia Ogólne</h2>
                <ol className="list-decimal list-inside">
                    <li>
                        Niniejszy Regulamin określa zasady korzystania z serwisu internetowego <strong>zdaj-to.pl</strong> (dalej: "Serwis"), prowadzonego przez Administratora.
                    </li>
                    <li>Serwis umożliwia użytkownikom (dalej: "Użytkownicy") zamieszczanie ogłoszeń dotyczących oferowania i poszukiwania korepetycji.</li>
                    <li>Korzystanie z Serwisu oznacza akceptację niniejszego Regulaminu.</li>
                    <li>Wszelkie prawa do Serwisu, w tym prawa autorskie oraz prawa własności intelektualnej, należą wyłącznie do Administratora.</li>
                </ol>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">§2. Definicje</h2>
                <ol className="list-decimal list-inside">
                    <li>
                        <strong>Serwis</strong> – platforma internetowa dostępna pod adresem www.zdaj-to.pl, umożliwiająca publikowanie i przeglądanie ogłoszeń związanych z korepetycjami.
                    </li>
                    <li>
                        <strong>Administrator</strong> – osoba zarządzająca Serwisem, posiadająca pełne prawo do modyfikacji, usuwania oraz zarządzania treściami zamieszczonymi w Serwisie.
                    </li>
                    <li>
                        <strong>Użytkownik</strong> – osoba fizyczna, prawna lub jednostka organizacyjna korzystająca z Serwisu w celu publikowania lub przeglądania ogłoszeń.
                    </li>
                </ol>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">§3. Obowiązki i Odpowiedzialność Użytkowników</h2>
                <ol className="list-decimal list-inside">
                    <li>Użytkownik zobowiązuje się do korzystania z Serwisu zgodnie z prawem i zasadami współżycia społecznego.</li>
                    <li>
                        Publikowanie treści obraźliwych, niezgodnych z prawem lub naruszających dobre obyczaje jest zakazane. Administrator zastrzega sobie prawo do ich usunięcia.
                    </li>
                    <li>Użytkownik ponosi pełną odpowiedzialność za treści zamieszczane w Serwisie.</li>
                    <li>
                        Użytkownik ma możliwość wyboru, czy jego numer telefonu i adres e-mail mają być widoczne w ogłoszeniach, za pomocą odpowiednich opcji w panelu profilu. Decyzja ta zależy wyłącznie od Użytkownika i wymaga jego wyraźnej zgody.
                    </li>
                </ol>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">§4. Uprawnienia Administratora</h2>
                <ol className="list-decimal list-inside">
                    <li>Administrator ma prawo do moderowania, edytowania, blokowania oraz usuwania dowolnych treści zamieszczanych przez Użytkowników w Serwisie.</li>
                    <li>Administrator nie ponosi odpowiedzialności za treści zamieszczane przez Użytkowników.</li>
                    <li>Administrator zastrzega sobie prawo do czasowego zawieszenia lub całkowitego zamknięcia Serwisu bez podania przyczyny.</li>
                    <li>
                        Decyzje Administratora są ostateczne i nie podlegają odwołaniu. Korzystanie z Serwisu odbywa się na wyłączną odpowiedzialność Użytkownika.
                    </li>
                </ol>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">§5. Postanowienia Końcowe</h2>
                <ol className="list-decimal list-inside">
                    <li>
                        Administrator zastrzega sobie prawo do wprowadzania zmian w Regulaminie. Zmiany wchodzą w życie z chwilą ich publikacji na stronie Serwisu.
                    </li>
                    <li>W przypadku naruszenia postanowień Regulaminu przez Użytkownika, Administrator może podjąć decyzję o zablokowaniu dostępu do Serwisu.</li>
                    <li>
                        Wszelkie spory wynikające z korzystania z Serwisu będą rozstrzygane przez sąd właściwy dla siedziby Administratora.
                    </li>
                </ol>
            </section>

            <p className="text-sm text-gray-500 mt-8 text-center">
                Korzystanie z Serwisu <strong>zdaj-to.pl</strong> jest równoznaczne z akceptacją niniejszego Regulaminu.
            </p>
        </div>
    );
};

export default Regulations;
