import {Breadcrumbs, ThemeProvider, Typography} from "@material-tailwind/react";

export default function AboutSite({theme}) {

    return (
        <>
            <Typography variant='h3' className='mb-2'>
                Czym zajmuje się nasz serwis
            </Typography>


            <ThemeProvider value={theme}>
                <Typography variant='lead'>
                    Zdaj-to.pl to innowacyjny serwis korepetycji stworzony z myślą o uczniach i korepetytorach,
                    którzy cenią sobie wygodę i efektywność. Nasza platforma umożliwia łatwe i szybkie
                    znajdowanie
                    ogłoszeń edukacyjnych na każdym poziomie nauczania – od szkoły podstawowej po studia wyższe.
                </Typography>
                <Typography variant='lead'>
                    Dla uczniów Zdaj-to.pl to idealne miejsce, by znaleźć wykwalifikowanych korepetytorów w
                    różnych
                    dziedzinach. Nasza baza danych obejmuje szeroki zakres przedmiotów, od matematyki i języków
                    obcych, przez nauki przyrodnicze, aż po sztuki piękne. Dzięki przejrzystemu i intuicyjnemu
                    interfejsowi, uczniowie mogą bez trudu wyszukiwać ogłoszenia według swoich preferencji,
                    przeglądać profile korepetytorów oraz zapoznawać się z opiniami innych uczniów.
                </Typography>
                <Typography variant='lead'>
                    Dla korepetytorów Zdaj-to.pl oferuje doskonałą okazję do zaprezentowania swoich umiejętności
                    i
                    doświadczenia. Nasi użytkownicy mogą tworzyć szczegółowe profile, w których prezentują swoje
                    kwalifikacje, metody nauczania oraz referencje. W ten sposób mogą skutecznie dotrzeć do
                    potencjalnych uczniów, którzy poszukują wsparcia w nauce.
                </Typography>
                <Typography variant='lead'>
                    Kluczowym elementem Zdaj-to.pl jest prostota obsługi. Nasz serwis został zaprojektowany tak,
                    aby
                    każdy mógł z niego korzystać bez względu na poziom zaawansowania technicznego. Rejestracja,
                    przeglądanie ogłoszeń oraz nawiązywanie kontaktu są niezwykle intuicyjne, co sprawia, że
                    cały
                    proces jest szybki i bezproblemowy.
                </Typography>
                <Typography variant='lead'>
                    Dbamy również o bezpieczeństwo naszych użytkowników. Każde ogłoszenie jest weryfikowane, a
                    użytkownicy mają możliwość zgłaszania nieodpowiednich treści. Nasz zespół administracyjny
                    regularnie monitoruje serwis, aby zapewnić najwyższy standard usług.
                </Typography>
                <Typography variant='lead'>
                    Zdaj-to.pl to więcej niż serwis korepetycji – to społeczność, która wspiera edukację na
                    każdym
                    kroku. Dołącz do nas już dziś i odkryj, jak proste i efektywne mogą być korepetycje w
                    nowoczesnym wydaniu!
                </Typography>
            </ThemeProvider>
        </>
    )
}