import {Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography} from "@material-tailwind/react";

export default function HowToTabs({fontColor = 'text-white'}) {
    return (
        <Tabs value="student" className='text-white'>
            <TabsHeader>
                <Tab key='student' value='student'>
                    Uczeń
                </Tab>

                <Tab key='tutor' value='tutor'>
                    Korepetytor
                </Tab>
            </TabsHeader>
            <TabsBody>
                <TabPanel value='student' key='student'>
                    <ul className={`list-disc pl-6 ${fontColor}`}>
                        <li>Wybierz interesujący cię przedmiot</li>
                        <li>Wybierz korepetytora</li>
                        <li>Oczekuj na kontakt ze strony korepetytora</li>
                    </ul>
                    <Typography variant='paragraph' className={`text-center my-1 font-bold ${fontColor}`}>
                        Albo:
                    </Typography>
                    <ul className={`list-disc pl-6 ${fontColor}`}>
                        <li>Utwórz ogłoszenie na temat interesujących cię korepetycji</li>
                        <li>Oczekuj na kontakt ze strony korepetytora</li>
                    </ul>
                </TabPanel>

                <TabPanel value='tutor' key='tutor'>
                    <ul className={`list-disc pl-6 ${fontColor}`}>
                        <li>Zarejestruj się</li>
                        <li>Wypełnij swój profil</li>
                        <li>Utwórz ogłoszenie korepetycji</li>
                        <li>Oczekuj na kontakt ze strony uczniów</li>
                    </ul>
                </TabPanel>
            </TabsBody>
        </Tabs>
    )
}