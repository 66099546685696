import {Header} from "../components/pageParts/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "../components/pageParts/Footer";
import ErrorScreen from "../components/ErrorScreen";
import {ErrorBoundary} from "react-error-boundary";
import {AlertProvider} from "../components/DisimisibleAlert";
import {createContext, useContext, useState} from "react";

const FooterVisibilityContext = createContext({
    isFooterVisible: true,
    setFooterVisibility: () => {},
});

export const useFooterVisibility = () => useContext(FooterVisibilityContext);

const Layout = () => {
    const [isFooterVisible, setFooterVisibility] = useState(true);

    return (
        <FooterVisibilityContext.Provider value={{ isFooterVisible, setFooterVisibility }}>
            <div className="flex flex-col min-h-screen">
                <Header />
                <ErrorBoundary FallbackComponent={ErrorScreen}>
                    <AlertProvider>
                        <Outlet />
                    </AlertProvider>
                </ErrorBoundary>
                {isFooterVisible && <Footer />}
            </div>
        </FooterVisibilityContext.Provider>
    );
};

export default Layout;
