import { Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@material-tailwind/react"; // Możesz użyć dowolnej biblioteki lub własnego stylu

export function ConversationBox({ topic, name, id, image, hasUnread, chosen = false }) {
    const navigate = useNavigate();

    return (
        <div
            className={`${
                chosen ? 'bg-blue-500 rounded text-white' : 'bg-white'
            } my-0.5 cursor-pointer w-full border-y border-blue-gray-100 p-2 select-none flex items-center space-x-2`}
            onClick={() => navigate(`/konwersacje/${id}`)}
        >
            {/* Obrazek użytkownika */}
            <div className="relative">
                <img src={image} alt={name} className="h-10 w-10 rounded-full" />
                {/* Wskaźnik dla nieprzeczytanych wiadomości */}
                {hasUnread && (
                    <div className="absolute -top-1 -right-1 w-3.5 h-3.5 bg-red-500 rounded-full border-2 border-white" />
                )}
            </div>

            {/* Informacje o konwersacji */}
            <div className="flex-1">
                <Typography className="font-semibold">{topic}</Typography>
                <Typography
                    className={`${
                        !chosen ? 'text-blue-gray-800' : 'text-white'
                    } relative text-sm bottom-1`}
                >
                    {name}
                </Typography>
            </div>
        </div>
    );
}
