import {createContext, useContext, useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {useLocation, useSearchParams} from "react-router-dom";
import {useAlert} from "../../DisimisibleAlert";

const FiltersFormContext = createContext();

const defaultFilterValues = {
    price_from: '',
    price_to: '',
    category: '',
    state: '',
    city: '',
    location: {
        tutor: false,
        student: false,
        online: false,
    },
    availability: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    },
    scope: {
        kindergarten: false,
        primary_school: false,
        high_school: false,
        college: false,
    },
    lesson_len_from: '',
    lesson_len_to: '',
    query: '',
    limit: '',
    has_phone: false,
    has_image: false,
    has_mail: false,
    has_reviews: false,
    page: 1,
};

export const FiltersFormProvider = ({ children }) => {
    const { register, control, getValues, resetField, handleSubmit, reset, setValue } = useForm({
        defaultValues: defaultFilterValues,
    });

    const [isSearchInputActive, setIsSearchInputActive] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [chips, setChips] = useState(defaultFilterValues);
    const showAlert = useAlert();
    const location = useLocation();

    const watch = useWatch({control});

    useEffect(() => {
        if (location.state?.query) {
            setValue('query', location.state.query);
            commitFilter(false);
            location.state.query = undefined;
        }

        if (!isSearchInputActive) {
            handleSubmit(onSubmit)();
        }
    }, [watch, handleSubmit]);

    async function onSubmit(data) {
        const urlSearchParams = new URLSearchParams();

        for (const [key, value] of Object.entries(data)) {
            if (typeof value === 'object') {
                if (Object.values(data[key]).includes(true)) {
                    urlSearchParams.append(key, JSON.stringify(value));
                }
            } else if (value && key !== 'watchRefresh') {
                urlSearchParams.append(key, value);
            }
        }

        setSearchParams(urlSearchParams);
        setChips(data);
    }

    function commitFilter(isInputActive) {
        setIsSearchInputActive(isInputActive);
        setValue('watchRefresh', 1);
    }

    function resetFilters() {
        reset(defaultFilterValues);
    }

    return (
        <FiltersFormContext.Provider
            value={{
                register,
                resetField,
                resetFilters,
                control,
                handleSubmit,
                setValue,
                chips,
                onSubmit,
                commitFilter,
                isSearchInputActive,
                setIsSearchInputActive,
            }}
        >
            {children}
        </FiltersFormContext.Provider>
    );
};

export const useFilters = () => useContext(FiltersFormContext);
