import React, {useContext} from "react";
import {
    Avatar,
    Button,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Typography,
} from "@material-tailwind/react";
import {
    Cog6ToothIcon,
    InboxArrowDownIcon,
    LifebuoyIcon,
    PowerIcon,
    UserCircleIcon,
} from "@heroicons/react/24/solid";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../AuthProvider";
import Divider from "../Divider";
import {createApiLink} from "../../utils/functions";


export default function HeaderProfile({className}) {
    const {user} = useContext(AuthContext);

    const profileMenuItems = [
        {
            label: "Mój profil",
            icon: UserCircleIcon,
            url: `/profil/${user.id}`
        },
        {
            label: "Wiadomości",
            icon: InboxArrowDownIcon,
            url: '/konwersacje'
        },
        {
            label: "Wyloguj się",
            icon: PowerIcon,
            url: '/wyloguj'
        },
    ];

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const navigate = useNavigate();


    const handleOnClick = (url) => {
        setIsMenuOpen(false);
        navigate(url);
    }

    return (
        <div className={className}>
            <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
                <MenuHandler>
                    <Button
                        variant="text"
                        color="blue-gray"
                        className="flex items-center rounded-full p-0 w-12"
                    >
                        <Avatar
                            variant="circular"
                            size="md"
                            alt="profile img"
                            withBorder={true}
                            color="blue"
                            className=" p-0.5"
                            src={createApiLink(user?.profile_image)}
                        />
                    </Button>
                </MenuHandler>
                <MenuList className="p-1">
                    <Typography as='p' className='text-center text-black font-bold hover:border-none'>
                        {user.firstname} {user.lastname}
                    </Typography>

                    <Divider className='!my-1' />

                    {profileMenuItems.map(({label, icon, url}, key) => {
                        const isLastItem = key === profileMenuItems.length - 1;
                        return (
                            <MenuItem
                                key={label}
                                onClick={() => handleOnClick(url)}
                                className={`flex items-center gap-2 rounded ${
                                    isLastItem
                                        ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                                        : ""
                                }`}
                            >
                                {React.createElement(icon, {
                                    className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                                    strokeWidth: 2,
                                })}
                                <Typography
                                    as="span"
                                    variant="small"
                                    className="font-normal"
                                    color={isLastItem ? "red" : "inherit"}
                                >
                                    {label}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </div>
    );
}