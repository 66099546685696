import React, {Suspense, useContext, useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
    Alert,
    Button,
    Card,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input,
    Switch,
    Textarea,
    Tooltip,
    Typography
} from "@material-tailwind/react";
import Divider from "../components/Divider";
import {AuthContext} from "../components/AuthProvider";
import api from "../utils/api";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import Home from "./Home";
import UserAnnouncementsPanel from "../components/pageParts/profile/UserAnnouncements";
import {OpinionSection} from "../components/OpinionSection";
import useGetData from "../utils/useGetData";
import Link from "../components/Link";
import {createApiLink} from "../utils/functions";

// Komponent przycisków akceptacji i odrzucenia
function AcceptDiscardButtons({className = '', okBtnFn, discardBtnFn}) {
    return (
        <div className={`flex items-center gap-2 ${className}`}>
            <Tooltip
                content="Zapisz"
                animate={{
                    mount: {scale: 1, y: 0},
                    unmount: {scale: 0, y: 25},
                }}
            >
                <button
                    className='bg-blue-500 p-1 rounded-lg hover:bg-blue-700 transition-all hover:-translate-y-0.5'
                    onClick={okBtnFn}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="white"
                         className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                    </svg>
                </button>
            </Tooltip>

            <Tooltip
                content="Odrzuć"
                animate={{
                    mount: {scale: 1, y: 0},
                    unmount: {scale: 0, y: 25},
                }}
            >
                <button
                    className='bg-blue-500 p-1 rounded-lg hover:bg-blue-700 transition-all hover:-translate-y-0.5'
                    onClick={discardBtnFn}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="white"
                         className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                    </svg>
                </button>
            </Tooltip>
        </div>
    )
}

// Komponent alertu aktualizacji
function UpdateAlert({success, open, setOpen, errorMsg = ''}) {
    const closeAlert = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                closeAlert();
            }, 3500);
            return () => clearTimeout(timer);
        }
    }, [open]);

    if (success) {
        return (
            <Alert open={open} onClose={closeAlert}
                   className='w-1/2 bottom-5 left-1/4 fixed bg-green-200 text-green-800'>
                Pomyślnie zaktualizowano
            </Alert>
        )
    }

    return (
        <Alert open={open} onClose={closeAlert} className='w-1/2 bottom-5 left-1/4 fixed bg-red-200 text-red-800'>
            Nie udało się zaktualizować. {errorMsg}.
        </Alert>
    )
}

// Komponent przycisku edycji
function EditButton({onClick, className = '', size = 4}) {
    return (
        <div className={className}>
            <Tooltip
                content="Edytuj"
                animate={{
                    mount: {scale: 1, y: 0},
                    unmount: {scale: 0, y: 25},
                }}
            >
                <button
                    onClick={onClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className={`size-${size}`}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"/>
                    </svg>
                </button>
            </Tooltip>
        </div>
    )
}

function SetProfileImage({open, setOpen}) {
    const tooltipContent = "Zdjęcie musi być w formacie png, jpeg, jpg oraz mieć rozmiar nie większy niż 2MB. " +
        "Sugerujemy wpierw przeskalować zdjęcie oraz przyciąć je do proporcji które nie spowodują efektu rozciągnięcia" +
        ", na przykład 1:1."
    const {id} = useParams();

    const [file, setFile] = useState({
        fileUrl: '',
        file: null
    });
    const [error, setError] = useState({
        isError: false,
        errorMessage: ''
    });
    const [loading, setLoading] = useState(false);

    function handleChange(e) {
        if (!e.target.files[0]) {
            setError({...error, isError: false});
            setFile({...file, file: null});
            return;
        }

        if (e.target.files[0].size > 2 * 1024 * 1024) {
            setError({
                isError: true,
                errorMessage: 'Plik jest zbyt duży, maksymalny rozmiar to 2MB.'
            });
            return;
        }

        const goodExtensions = ['jpeg', 'png', 'jpg'];
        const filenameSplit = e.target.files[0].name.split('.');
        if (!goodExtensions.includes(filenameSplit[filenameSplit.length - 1])) {
            setError({
                isError: true,
                errorMessage: 'Plik ma nieprawidłowe rozszerzenie.'
            });
            return;
        }

        setError({...error, isError: false});
        setFile({
            file: e.target.files[0],
            fileUrl: URL.createObjectURL(e.target.files[0])
        });
    }

    async function handleSubmit() {
        if (!file.file) {
            setError({
                isError: true,
                errorMessage: 'Proszę wybrać zdjęcie.',
            });
            return;
        }

        const formData = new FormData();
        formData.append('profileImage', file.file);

        setLoading(true);

        try {
            await api.put(`/profile/update-image/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setError(prevState => ({...prevState, isError: false}));
            setOpen(false);
        } catch (err) {
            const errorMessage = err.response?.data?.errors?.[0] || 'Coś poszło nie tak';
            setError({
                isError: true,
                errorMessage,
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog open={open}>
            <DialogHeader>
                <Typography variant='h3'>
                    Ustaw nowe zdjęcie profilowe
                </Typography>
            </DialogHeader>

            <DialogBody>
                <Typography variant='paragraph' className='font-bold text-blue-gray-900'>
                    Wybierz zdjęcie profilowe
                </Typography>

                <Typography className='text-xs py-1'>
                    {tooltipContent}
                </Typography>

                <div className='mt-3 mb-2'>
                    <Input type="file" label='Upuść lub wybierz zdjęcie' onChange={handleChange}/>
                </div>

                {error.isError && (
                    <Typography className='text-red-600 text-sm'>
                        {error.isError && error.errorMessage}
                    </Typography>
                )}

                {!error.isError && file.file && (
                    <div className='flex w-full items-center flex-col'>
                        <Typography className='text-center pt-2 pb-3' variant='paragraph'>
                            Podgląd
                        </Typography>
                        <img src={file.fileUrl} className='h-40 w-40 rounded-full'/>
                    </div>
                )}

            </DialogBody>

            <DialogFooter className='flex gap-3'>
                <Button color='red' onClick={() => setOpen(false)}>Odrzuć</Button>
                <Button color='green' onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Wysyłanie...' : 'Akceptuj'}
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

// Główny komponent MyProfile
function MyProfile() {
    const {user} = useContext(AuthContext);
    const {id} = useParams();
    const navigate = useNavigate();
    const rating = useGetData(`/profile/${user.id}/rating`);

    // Ustawienie domyślnych wartości formularza
    const defaultValues = {
        profile_description: user.profile_description || '',
        profile_phone: user.profile_phone || '',
        profile_school: user.profile_school || '',
        profile_education_city: user.profile_education_city || '',
    };

    const {handleSubmit, control, watch, register, formState: {errors}, reset} = useForm({
        defaultValues
    });

    const [editSection, setEditSection] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [imagePickerOpen, setImagePickerOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('/images/default-profile.png')

    // Funkcja obsługująca kliknięcie przycisku edycji
    const handleEditClick = (section) => {
        setEditSection(section);
    };

    // Funkcja obsługująca odrzucenie zmian
    const handleDiscard = () => {
        setEditSection(null);
        reset(defaultValues); // Resetowanie wartości do domyślnych
    };

    // Funkcja obsługująca zatwierdzenie zmian
    const onSubmit = async (data) => {
        // Sprawdzenie, czy dane są niezmienione
        const isDataUnchanged = Object.keys(defaultValues).every(
            (key) => defaultValues[key] === data[key]
        );

        if (!isDataUnchanged) {
            try {
                const res = await api.put(`/profile/${id}`, data);

                if (res.status === 200) {
                    setAlertOpen(true);
                    setAlertSuccess(true);
                    // Aktualizacja domyślnych wartości po sukcesie
                    reset(data);
                }
            } catch (e) {
                setAlertOpen(true);
                setAlertSuccess(false);
                setErrorMsg(e.response?.data?.errors[0] || 'Nieoczekiwany błąd.');
            }
        }

        setEditSection(null);
    };

    const onSubmitAgreements = async (e) => {
        try {
            const data = {};
            data[e.target.name] = e.target.checked;

            const res = await api.put(`/profile/${id}/agreements`, data);

            if (res.status === 200) {
                navigate(0);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertSuccess(false);
            setErrorMsg(e.response?.data?.errors[0] || 'Nieoczekiwany błąd.');
        }
    };

    const isMounted = useRef(false)
    useEffect(() => {
        async function updateImage() {
            if (!isMounted.current) {
                isMounted.current = true;
                return;
            }

            if (!imagePickerOpen) {
                try {
                    const res = await api.get('/me');
                    setImageUrl(res.data.profile_image);
                } catch (e) {
                    return <Navigate to={Home}/>
                }
            }
        }

        updateImage();
    }, [imagePickerOpen]);

    return (
        <>
            <SetProfileImage open={imagePickerOpen} setOpen={setImagePickerOpen}/>
            <Card className='w-full md:w-4/5 flex flex-col self-center my-4 px-4 py-4 md:px-6 md:py-6' shadow={false}>
                <div className='flex flex-col items-center'>
                    {/* Zdjęcie profilowe */}
                    <img
                        className="h-32 w-32 rounded-full object-cover mb-4"
                        src={createApiLink(imageUrl)}
                        alt="Profile"
                    />

                    <div className='relative'>
                        <EditButton
                            className='text-white absolute -top-12 left-8 w-8 h-8 bg-blue-500 hover:bg-blue-700 transition-all flex justify-center rounded-full'
                            size={5}
                            onClick={() => setImagePickerOpen(true)}
                        />
                    </div>

                    {/* Nazwa użytkownika */}
                    <Typography variant='h4' className='font-bold text-center'>
                        {user.firstname + " " + user.lastname}
                    </Typography>
                    {/* Ostatnia aktywność */}
                    <Typography variant='small'>
                        Ocena: {rating?.rating ? Number(rating?.rating).toFixed(2) : "Brak"}
                    </Typography>

                    {/* Data założenia konta */}
                    <Typography variant='small' className='text-gray-500'>
                        Konto założone: {new Date(user.created_at).toLocaleDateString()}
                    </Typography>
                </div>

                <Divider className='my-4'/>

                {/* Opis Profilu */}
                <div className='flex gap-3 items-center'>
                    <Typography variant='lead' className='font-bold px-2'>
                        Opis
                    </Typography>

                    <EditButton onClick={() => handleEditClick('profile_description')}/>

                    {editSection === 'profile_description' && (
                        <AcceptDiscardButtons
                            className='ml-auto'
                            okBtnFn={handleSubmit(onSubmit)}
                            discardBtnFn={handleDiscard}
                        />
                    )}
                </div>

                <div className='flex items-center'>
                    {editSection === 'profile_description' ? (
                        <>
                            <Controller
                                name='profile_description'
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 400,
                                        message: 'Opis musi mieć do 400 znaków.'
                                    }
                                }}
                                render={({field}) => (
                                    <Textarea
                                        {...field}
                                        className='w-full'
                                        placeholder="Opis profilu"
                                    />
                                )}
                            />
                            {errors.profile_description && (
                                <Typography variant='small' color='red' className='mt-1'>
                                    {errors.profile_description.message}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Typography variant='paragraph' className='my-2 mx-4'>
                            {watch('profile_description') || 'Brak opisu.'}
                        </Typography>
                    )}
                </div>

                <Divider className='my-4'/>

                {/* Dane Kontaktowe */}
                <div className='flex gap-3 items-center'>
                    <Typography variant='lead' className='font-bold px-2'>
                        Dane kontaktowe
                    </Typography>

                    <EditButton onClick={() => handleEditClick('contactInfo')}/>

                    {editSection === 'contactInfo' && (
                        <AcceptDiscardButtons
                            className='ml-auto'
                            okBtnFn={handleSubmit(onSubmit)}
                            discardBtnFn={handleDiscard}
                        />
                    )}

                </div>

                <div className='flex flex-col w-full'>
                    {editSection === 'contactInfo' ? (
                        <>
                            <div className='flex gap-2 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                </svg>
                                {user?.email}
                            </div>

                            <Controller
                                name='profile_phone'
                                control={control}
                                rules={{
                                    required: 'Numer telefonu jest wymagany.',
                                    pattern: {
                                        value: /^[0-9]{9}$/, // Przykładowy regex dla 9-cyfrowego numeru
                                        message: 'Niepoprawny numer telefonu.'
                                    }
                                }}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        placeholder="Telefon"
                                        className='mt-2'
                                    />
                                )}
                            />
                            {errors.profile_phone && (
                                <Typography variant='small' color='red' className='mt-1'>
                                    {errors.profile_phone.message}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Typography variant='paragraph' className='px-5'>
                            <div className='flex gap-2 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                </svg>
                                {user?.email}
                            </div>
                            <div className='flex gap-2 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                </svg>
                                {watch('profile_phone') || 'Brak numeru telefonu.'}
                            </div>
                        </Typography>
                    )}
                </div>

                <Divider className='my-4'/>
                {/* Informacje o Edukacji */}
                <div className='flex gap-3 items-center'>
                    <Typography variant='lead' className='font-bold px-2'>
                        Edukacja
                    </Typography>

                    <EditButton onClick={() => handleEditClick('education')}/>

                    {editSection === 'education' && (
                        <AcceptDiscardButtons
                            className='ml-auto'
                            okBtnFn={handleSubmit(onSubmit)}
                            discardBtnFn={handleDiscard}
                        />
                    )}
                </div>

                <div className='flex items-center'>
                    {editSection === 'education' ? (
                        <div className='flex flex-col gap-1 md:flex-row'>
                            <Controller
                                name='profile_school'
                                control={control}
                                rules={{
                                    required: 'Nazwa szkoły jest wymagana.',
                                    minLength: {
                                        value: 3,
                                        message: 'Nazwa szkoły musi mieć co najmniej 3 znaki.'
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: 'Nazwa szkoły może mieć maksymalnie 100 znaków.'
                                    }
                                }}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        placeholder="Szkoła"
                                        className='mb-2'
                                    />
                                )}
                            />
                            {errors.profile_school && (
                                <Typography variant='small' color='red' className='mt-1'>
                                    {errors.profile_school.message}
                                </Typography>
                            )}

                            <Controller
                                name='profile_education_city'
                                control={control}
                                rules={{
                                    required: 'Miasto edukacji jest wymagane.',
                                    minLength: {
                                        value: 2,
                                        message: 'Miasto edukacji musi mieć co najmniej 2 znaki.'
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: 'Miasto edukacji może mieć maksymalnie 50 znaków.'
                                    },
                                    pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message: 'Miasto edukacji musi składać się tylko z liter.'
                                    }
                                }}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        placeholder="Miasto"
                                        className='mb-2'
                                    />
                                )}
                            />
                            {errors.profile_education_city && (
                                <Typography variant='small' color='red' className='mt-1'>
                                    {errors.profile_education_city.message}
                                </Typography>
                            )}
                        </div>
                    ) : (
                        <Typography variant='paragraph' className='px-5'>
                            {watch('profile_school') || 'Brak szkoły'}, {watch('profile_education_city') || 'Brak miasta.'}
                        </Typography>
                    )}
                </div>

                <Divider className='my-4'/>
                <Typography variant='lead' className='font-bold px-2'>
                    Zgody
                </Typography>
                <div className='ml-5 flex flex-col'>
                    <Switch checked={user.show_email} label={"Udostępniaj email w moich ogłoszeniach"} name='show_email' onChange={onSubmitAgreements}/>
                    <Switch checked={user.show_phone} label={"Udostępniaj numer telefonu w moich ogłoszeniach"} name="show_phone" onChange={onSubmitAgreements}/>
                    <Switch checked={user.mailing} label={"Wiadomości email od serwisu"} name="mailing_agreement" onChange={onSubmitAgreements}/>
                </div>

                <Divider className='my-4'/>

                <Typography variant='lead' className='font-bold px-2'>
                    Hasło
                </Typography>
                <Link className='ml-5' blank={false} href='/resetuj-haslo'>Zmień hasło</Link>

                <Divider className='my-4'/>


                {/* Ogłoszenia użytkownika */}
                <Typography variant='lead' className='font-bold px-2'>
                    Ogłoszenia korepetycji
                </Typography>

                <div className='ml-3'>
                    <UserAnnouncementsPanel url={`/profile/${id}/tutor`}/>
                </div>

                <Divider className='my-4'/>

                {/* Ogłoszenia o poszukiwaniu korepetytora */}
                <Typography variant='lead' className='font-bold px-2'>
                    Ogłoszenia o poszukiwaniu korepetytora
                </Typography>
                <div className='ml-3'>
                    <UserAnnouncementsPanel url={`/profile/${id}/student`} isStudent={true}/>
                </div>

                {/* Sekcja opinii */}
                <Suspense fallback={<p>Ładuje opinie</p>}>
                    <OpinionSection id={id} showAddOpinion={false} source='profile'/>
                </Suspense>
            </Card>

            {/* Komponent alertu aktualizacji */}
            <UpdateAlert
                open={alertOpen}
                setOpen={setAlertOpen}
                success={alertSuccess}
                errorMsg={errorMsg}
            />
        </>
    )
}

export default MyProfile;
