import {Button, Card, Checkbox, Input, Typography} from "@material-tailwind/react";
import {useForm} from "react-hook-form";
import {useEffect, useRef, useState} from "react";
import api from "../../../utils/api";
import Link from "../../Link";
import {useAlert} from "../../DisimisibleAlert";
import {requirementsLabels} from "../../../utils/constants";

function ActivateNotificationCard({userId}) {
    const [seconds, setSeconds] = useState(0);
    const showAlert = useAlert();

    async function handleResendButton() {
        setSeconds(180);
        try {
            await api.get(`/resend/${userId}`);
            showAlert("Mail aktywacyjny został wysłany ponownie.");
        } catch (e) {
            showAlert("Musi minąć 3 minuty zanim wyślemy mail aktywacyjny ponownie.");
        }
    }

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(p => p - 1), 1000);
        }
    }, [seconds, setSeconds]);

    return (
        <div className='min-h-[80vh] flex justify-center items-center mt-5 md:mt-6 mb-3'>
            <Card className='w-11/12 md:w-2/5 p-4 h-fit'>
                <Typography variant='h2' className='text-black text-center pb-4'>
                    Aktywuj konto
                </Typography>

                <Typography variant='lead' className='text-blue-gray-900'>
                    Rejestracja przebiegła pomyślnie, ale na ten moment twoje konto nie jest aktywne, aby móc korzystać
                    z
                    serwisu, <b>aktywuj swoje konto</b> poprzez <b>link</b> który wysłaliśmy na podany podczas
                    rejestacji
                    adres <b>email</b>. <span className='text-red-500 font-bold'>Sprawdź spam</span>
                </Typography>

                <Button size='sm' className='w-1/2 mx-auto mt-7' color='light-blue' disabled={seconds > 0}
                        onClick={handleResendButton}>
                    Wyślij link ponownie {seconds > 0 && `(${seconds})`}
                </Button>

                <Typography variant='laed' className='inline-block mx-auto pt-1'>Aktywowałeś już konto? <Link
                    href='/logowanie' blank={false}>Zaloguj się</Link></Typography>
            </Card>
        </div>
    )
}

export default function SignUpCard() {
    const {register, handleSubmit, setError, formState: {errors, isSubmitting}} = useForm();
    const [showActivateScreen, setShowActivateScreen] = useState(false);
    const userId = useRef(null);

    async function submitForm(data) {
        try {
            const apiData = {
                firstname: data.firstName,
                lastname: data.lastName,
                email: data.email,
                password: data.password
            };

            const res = await api.post('/sign-up', apiData);
            if (res.data.isValid) {
                setShowActivateScreen(true);
                userId.current = res.data.userId;
            }
        } catch (e) {
            setError('root', {
                message: e.response.data.errors ? e.response.data.errors[0] : "Coś poszło nie tak.",
            });
        }
    }

    if (showActivateScreen) {
        return <ActivateNotificationCard userId={userId.current}/>
    }

    return (
        <div className='min-h-[80vh] flex items-center justify-center mt-5 md:mt-6 mb-3'>
            <Card className='md:p-8 flex items-center md:border md:border-blue-gray-50 md:shadow-xl' shadow={false}>
                <div
                    className='bg-gradient-to-r from-cyan-500 to-blue-500 flex flex-col gap-2 rounded-2xl py-2 px-4 text-white w-[90%]'>
                    <Typography variant="h4">
                        Zarejestruj się
                    </Typography>
                    <Typography className="mt-1 font-normal">
                        Witaj! Wypełnij formularz aby się zarejstrować.
                    </Typography>
                </div>

                <form className="mt-4 mb-2 w-80 max-w-screen-lg sm:w-96 px-4">
                    <div className="mb-1 flex flex-col gap-3">

                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            Imie
                        </Typography>
                        <Input
                            {...register("firstName", {
                                required: 'Imie jest wymagane.',
                                pattern: {
                                    value: /^[A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż]+$/,
                                    message: "Imie może składać się tylko z liter.",
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Imie musi mieć przynajmniej 3 litery.'
                                },
                                maxLength: {
                                    value: 14,
                                    message: 'Twoje imie jest zbyt długie.'
                                }
                            })}
                            error={!!errors.firstName}
                            size="lg"
                            placeholder="Jan"
                            className={!!errors.firstName ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}

                        />
                        {errors.firstName && <div className='auth-form-err'>{errors.firstName.message}</div>}


                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            Nazwisko
                        </Typography>
                        <Input
                            {...register("lastName", {
                                required: 'Nazwisko jest wymagane.',
                                pattern: {
                                    value: /^[A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż]+([ -][A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż]+)?$/,
                                    message: "Nazwisko może składać się tylko z liter.",
                                },
                                minLength: {
                                    value: 2,
                                    message: 'Nazwisko musi mieć przynajmniej 2 litery.'
                                },
                                maxLength: {
                                    value: 40,
                                    message: 'Twoje nazwisko jest zbyt długie.'
                                }
                            })}
                            error={!!errors.lastName}
                            className={!!errors.lastName ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                            size="lg"
                            placeholder="Kowalski"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {errors.lastName && <div className='auth-form-err'>{errors.lastName.message}</div>}

                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            Email
                        </Typography>
                        <Input
                            {...register("email", {
                                required: 'Email jest wymagany.',
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Niepoprawny format emaila.",
                                },
                                maxLength: {
                                    value: 64,
                                    message: 'Email zbyt długi.'
                                }
                            })}
                            type='email'
                            error={!!errors.email}
                            className={!!errors.email ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                            size="lg"
                            placeholder="janusz@mail.com"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {errors.email && <div className='auth-form-err'>{errors.email.message}</div>}


                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            Hasło
                        </Typography>
                        <Input
                            {...register("password", {
                                required: 'Hasło jest wymagane.',
                                pattern: {
                                    value: /^(?=.*[A-Z])(?=.*\d).{8,}$/,
                                    message: "Hasło nie spełnia wymogów.",
                                },
                                maxLength: {
                                    value: 256,
                                    message: 'Hasło jest zbyt długie.'
                                }
                            })}
                            error={!!errors.password}
                            className={!!errors.password ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                            type="password"
                            size="lg"
                            placeholder="********"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {errors.password && <div className='auth-form-err'>{errors.password.message}</div>}

                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            Powtórz hasło
                        </Typography>
                        <Input
                            {
                                ...register("confirmPassword", {
                                    validate: (value, formValues) => {
                                        return value !== formValues.password ? 'Hasła nie są takie same.' : true
                                    }
                                })
                            }
                            error={!!errors.confirmPassword}
                            className={!!errors.confirmPassword ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                            type="password"
                            size="lg"
                            placeholder="********"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        <Typography
                            variant="small"
                            color="gray"
                            className="flex items-center gap-1 font-normal text-xs"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            {requirementsLabels.password}
                        </Typography>
                        {errors.confirmPassword &&
                            <div className='auth-form-err'>{errors.confirmPassword.message}</div>}
                    </div>
                    <Checkbox
                        {...register("agreement", {
                            required: 'Zgoda jest wymagana',
                        })}

                        label={
                            <Typography
                                variant="small"
                                color="gray"
                                className={`flex items-center font-normal ${errors.agreement && 'text-red-500'}`}
                            >
                                Akceptuje <Link href={process.env.PUBLIC_URL + '/regulamin.pdf'} blank> regulamin </Link> strony
                            </Typography>
                        }

                        color='light-blue'
                        containerProps={{className: "-ml-2.5"}}
                        labelProps={{className: "!text-white"}}
                    />
                    {/*{errors.agreement && <div className='auth-form-err mb-4'>{errors.agreement.message}</div>}*/}
                    {errors.root && <div className='auth-form-err'>{errors.root.message}</div>}

                    <Button disabled={isSubmitting} className="bg-gradient-to-r from-cyan-500 to-blue-500" fullWidth
                            onClick={handleSubmit(submitForm)}>
                        {isSubmitting ? 'Rejestowanie...' : 'Zarejestruj sie'}
                    </Button>

                    <Typography color="gray" className="mt-4 text-center">
                        Masz już konto?
                        <a href='/logowanie'>
                            <Button variant='outlined' size='sm' className='ml-1 border-cyan-800 rounded-md'
                                    ripple={false}>
                                Zaloguj się
                            </Button>
                        </a>
                    </Typography>
                </form>
            </Card>
        </div>
    );
}