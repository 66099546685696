import {Button, Card, CardBody, CardHeader, Option, Select, Typography} from "@material-tailwind/react";
import {Pagination} from "../../Pagination";
import {useContext, useEffect} from "react";
import {AnnouncementDataContext} from "../../../pages/Announcements";
import {Controller} from "react-hook-form";
import {useFilters} from "./FiltersFormProvider";
import {FilterChips} from "../../FilterChipsSection";
import {createApiLink} from "../../../utils/functions";


export default function AnnouncementsComp() {
    const {announcementsData} = useContext(AnnouncementDataContext);
    const {control, setValue, chips, resetField, resetFilters, setIsSearchInputActive} = useFilters();
    let announcements = announcementsData?.announcements;

    useEffect(() => {
        if (!announcements?.currentPage) {
            setValue('page', 1);
        }

        if (announcementsData?.filtersApplied) {
            setIsSearchInputActive(true);
            if (announcementsData.filtersApplied.category) {
                setValue('category', announcementsData.filtersApplied.category);
            }
            if (announcementsData.filtersApplied.city) {
                setValue('city', announcementsData.filtersApplied.city);
            }
            if (announcementsData.filtersApplied.newQuery) {
                setValue('query', announcementsData.filtersApplied.newQuery.replaceAll(',', ' '));
            } else {
                setValue('query', '');
            }
            setIsSearchInputActive(false);
        }
    }, [announcements]);

    return (
        <div className='w-full md:mx-3'>
            <div className='flex w-full flex-col-reverse md:flex-row md:h-14 md:items-center'>
                <Typography variant='h4' className='text-center'>Ogłoszenia korepetytorów</Typography>

                <div className='ml-0 md:ml-auto py-1'>
                    <Controller
                        name="sort"
                        control={control}
                        render={({field}) => (
                            <Select variant="outlined" label="Sortowanie" className='w-full md:w-52 h-10' value={field.value}
                            onChange={field.onChange} onBlur={field.onBlur} ref={field.ref}>
                                <Option value='rating'>Ocena</Option>
                                <Option value='date' defaultChecked>Najnowsze</Option>
                                <Option value='price_desc'>Cena malejąco</Option>
                                <Option value='price_asc'>Cena rosnąco</Option>
                            </Select>
                        )}
                    />
                </div>
            </div>

            <hr className='my-1 bg-blue-gray-800 h-1'/>

            <FilterChips data={chips} resetField={resetField} resetAll={resetFilters} className='px-3' />

            { announcementsData?.totalPages > 1 ?
                (
                    <div className='flex justify-center mt-4'>
                        <Pagination pages={announcementsData?.totalPages} setPage={setValue} currentPage={announcementsData?.currentPage}/>
                    </div>
                ) :
                null
            }

            <div className='flex flex-wrap w-full justify-center lg:justify-evenly'>
                {announcements?.map(a => (
                    <Card className="w-[98%] lg:w-[47%] flex-row my-3 shadow-md">
                        <CardBody className="flex flex-wrap w-full">
                            <CardHeader
                                shadow={false}
                                floated={false}
                                className="shrink-0 flex-grow-0 mr-4 mb-4"
                            >
                                <img
                                    src={createApiLink(a?.profile_image)}
                                    alt="card-image"
                                    className="h-20 w-20 md:h-32 md:w-32 lg:h-40 lg:w-40 object-cover rounded-lg"
                                />

                                <div className='block text-center mt-1'>
                                    {a?.average_rating && parseFloat(a.average_rating) != 0.0 ? (
                                        <Typography className='font-bold text-black'>
                                            Ocena: {Number(a.average_rating).toFixed(2)}
                                        </Typography>
                                    ) : (
                                        <Typography className='font-bold text-black'>
                                            Ocena: Brak
                                        </Typography>
                                    )}
                                </div>
                            </CardHeader>
                            <div className="flex-1 max-w-full">
                                <Typography variant="h6" color="gray" className="mb-4 uppercase">
                                    {a?.category_name}
                                    {a?.lesson_length ? (
                                        <Typography>
                                            {a?.price} zł / {a?.lesson_length} minut
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {a?.price} zł
                                        </Typography>
                                    )}
                                </Typography>
                                <Typography variant="h4" color="blue-gray" className="mb-2">
                                    {a?.firstname}
                                </Typography>

                                <Typography color="gray" className="mb-8 font-normal w-full break-words">
                                    {a?.short_description}
                                </Typography>
                                <a href={`/ogloszenie/${a?.id}`} className="inline-block">
                                    <Button variant="text" className="flex items-center gap-2">
                                        Ogłoszenie
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            className="h-4 w-4"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                            />
                                        </svg>
                                    </Button>
                                </a>
                            </div>
                        </CardBody>
                    </Card>
                ))}

            </div>
            { announcementsData?.totalPages > 1 ?
                (
                    <div className='flex justify-center'>
                        <Pagination pages={announcementsData?.totalPages} setPage={setValue} currentPage={announcementsData?.currentPage} announcements={announcements} />
                    </div>
                ) :
                null
            }
        </div>
    )
}