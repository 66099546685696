import React from 'react';

function LinkIcon({className}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             className={`w-4 h-4 ${className}`}>
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
        </svg>
    );
}

export default function Link({children, href, blank = true, className = ''}) {
    return (
        <a
            href={href}
            target={blank && '_blank'}
            className={`cursor-pointer inline-flex items-center text-blue-400 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 ${className}`}
        >
            <span
                className='border-b border-transparent hover:border-blue-400 transition duration-200 ease-in-out italic'>
                {children}
            </span>
            <LinkIcon className='ml-1'/>
        </a>
    );
}
