import {Button, Card, CardBody, Input, Typography} from "@material-tailwind/react";
import {useForm} from "react-hook-form";
import {useContext} from "react";
import {AuthContext} from "../components/AuthProvider";
import api from "../utils/api";
import {useAlert} from "../components/DisimisibleAlert";
import {useNavigate, useSearchParams} from "react-router-dom";
import {requirementsLabels} from "../utils/constants";

export default function ResetPassword({ variant = 'forgot' }) {
    const { register, handleSubmit, setError, formState: {errors} } = useForm();
    const showAlert = useAlert();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    if (!searchParams.get('token')) {
        variant = 'change'
    }

    const inputs = variant === 'forgot'
        ? [
            { name: 'newPassword', label: 'Nowe hasło' },
            { name: 'confirmNewPassword', label: 'Powtórz Nowe Hasło' }
        ]
        : [
            { name: 'oldPassword', label: 'Stare hasło'},
            { name: 'newPassword', label: 'Nowe hasło' },
            { name: 'confirmNewPassword', label: 'Powtórz Nowe Hasło' }
        ];

    const onSubmit = async (data) => {
        if (data.newPassword !== data.confirmNewPassword) {
            setError('root', {message: 'Potwórzone hasło jest niezgodne.'});
            return;
        }

        try {
            await api.post('/reset-password', {...data, token: searchParams.get('token')});
            navigate('/');
            showAlert('Pomyślnie ustawiono nowe hasło');
        } catch (e) {
            if (e.response?.data?.message) {
                setError('root', {message: e.response?.data?.message});
            } else {
                setError('root', {message: 'Coś poszło nie tak'});
            }
        }
    };

    return (
        <div className='h-[83vh] flex justify-center items-center'>
            <Card className='w-10/12 md:w-1/3'>
                <Typography variant='h3' className='text-center'>Resetuj hasło</Typography>

                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {inputs.map((input, index) => (
                            <div key={index} className="mb-4">
                                {/*<label>{input.label}:</label>*/}
                                <Input
                                    variant='static'
                                    label={input.label}
                                    placeholder='**********'
                                    type='password'
                                    {...register(input.name)}
                                />
                            </div>
                        ))}

                        <Typography
                            variant="small"
                            color="gray"
                            className="flex items-center gap-1 font-normal text-xs"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            {requirementsLabels.password}
                        </Typography>

                        {errors.root && (
                            <Typography className='auth-form-err'>{errors.root.message}</Typography>
                        )}

                        <Button type='submit' className='mt-2'>Ustaw hasło</Button>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
}
