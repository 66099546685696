import React, {createContext, useContext, useState, useEffect, useRef} from "react";
import { Alert, Button } from "@material-tailwind/react";

const AlertContext = createContext();

export const useAlert = () => {
    return useContext(AlertContext);
};


export const AlertProvider = ({ children }) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const timeoutId = useRef(null);


    const showAlert = (message) => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        setAlertMessage(message);
        setAlertOpen(true);

        timeoutId.current = setTimeout(() => {
            setAlertOpen(false);
        }, 2000);
    };

    const handleClose = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        setAlertOpen(false);
    }

    return (
        <AlertContext.Provider value={showAlert}>
            {children}
            <div className=" fixed bottom-4 right-4">
                <Alert open={alertOpen} onClose={handleClose} className="w-72">
                    {alertMessage}
                </Alert>
            </div>
        </AlertContext.Provider>
    );
};
