import {SearchInput} from "./SearchInput";
import {Button} from "@material-tailwind/react";
import {useRef} from "react";
import {useNavigate} from "react-router-dom";

export default function SearchBar({className}) {
    const inputRef = useRef(null);

    const navigate = useNavigate();
    const search = () => {
        console.log(inputRef.current.value.replaceAll(' ', ','));
        const queryString = inputRef.current.value.replaceAll(' ', ',');
        navigate('/ogloszenia', { state: { query: queryString } });
    }

    return (
        <div className={`flex h-10 w-full ${className}`}>
            <SearchInput data={['_']} minCharacters={99999} ref={inputRef} />
            <Button className='ml-2 bg-gradient-to-r from-cyan-500 to-blue-500' onClick={search}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="size-5">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
                </svg>
            </Button>
        </div>
    )
}