import {Card, ThemeProvider, Typography} from "@material-tailwind/react";
import {createContext} from "react";
import AboutSite from "../components/pageParts/about/aboutSite";
import AboutTeam from "../components/pageParts/about/AboutTeam";
import Contact from "../components/pageParts/about/Contact";
import SupportUs from "../components/pageParts/about/SupportUs";


export default function About() {
    const theme = {
        typography: {
             styles: {
                 variants: {
                     lead: {
                         marginTop: 'mt-3'
                     }
                 }
             }
        }
    }

    return (
        <>
            <div className='relative'>
                <div className='absolute -z-10 bg-gradient-to-r from-cyan-500 to-blue-500 w-full h-72'></div>
            </div>

            <Typography variant='h2' className='mt-16 mb-8 text-white text-center'>O naszym serwisie</Typography>
            <div className='flex justify-center'>
                <Card className='w-10/12 p-10'>
                    <AboutSite theme={theme}/>
                    <hr className='h-0.5 w-full bg-blue-gray-200 mt-3 mb-5'/>
                    <AboutTeam theme={theme}></AboutTeam>
                    <hr className='h-0.5 w-full bg-blue-gray-200 mt-3 mb-5'/>
                    <Contact/>
                    <hr className='h-0.5 w-full bg-blue-gray-200 mt-3 mb-5'/>
                    <SupportUs theme={theme} />
                </Card>
            </div>
        </>
    )
}