import {
    Button,
    Card,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input,
    Typography
} from "@material-tailwind/react";
import {useForm} from "react-hook-form";
import api from "../../../utils/api";
import AuthSuccessDialog from "./AuthSuccessDialog";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Link from "../../Link";
import {useAlert} from "../../DisimisibleAlert";

function SendResetPasswordEmailDialog({open, setOpen}) {
    const showAlert = useAlert();
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');

    const handleOk = async () => {
        try {
            await api.get(`/reset-password/${email}`);
            setOpen(false);
            showAlert('Wysłano link resetujący na podany adres email.');
        } catch (e) {
            if (e.response?.data?.message) {
                if (e.response?.data?.remain) {
                    setError(e.response?.data?.message + " Pozostało: " + Math.round(e.response?.data?.remain) + " sekund.");
                } else {
                    setError(e.response?.data?.message);
                }
            } else {
                setError('Coś poszło nie tak.')
            }
        }
    }

    return (
        <Dialog open={open}>
            <DialogHeader>Zresetuj hasło</DialogHeader>
            <DialogBody>
                <Typography>
                    Aby zresetować hasło kliknij w link który zostanie przesłany na podany przez ciebie adres email.
                </Typography>

                <Input name='email' placeholder='abc@mail.com' value={email}
                       onChange={(e) => setEmail(e.target.value)}/>
                <div className='auth-form-err !mt-1'>{error}</div>
            </DialogBody>
            <DialogFooter>
                <Button color='red' onClick={() => setOpen(!open)}>Anuluj</Button>
                <Button color='light-blue' onClick={handleOk} className='ml-3'>Wyślij email</Button>
            </DialogFooter>
        </Dialog>
    )
}

export default function SignInCard() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setError,
        formState: {
            errors
        }
    } = useForm();

    async function submitForm(data) {
        try {
            const apiData = {
                email: data.email,
                password: data.password
            };

            const res = await api.post('/sign-in', apiData);
            if (res.data.message === 'logged in' && res.status === 200) {
                navigate('/');
                navigate(0);
            }
        } catch (e) {
            if (e.response?.data.errors) {
                setError('root',{ message: e.response?.data?.errors[0]});
            }
            else {
                setError('root',{ message: 'Coś poszło nie tak.'});
            }
        }
    }

    return (
        <>
            <SendResetPasswordEmailDialog open={resetOpen} setOpen={setResetOpen}/>
            <AuthSuccessDialog open={dialogOpen} header={'Pomyślnie zalogowano'}/>
            <div className='min-h-[80vh] flex items-center justify-center mt-5 md:mt-6 mb-3'>
                <Card
                    className='md:p-8 min-h-fit flex items-center md:border md:border-blue-gray-50 md:shadow-xl'
                    shadow={false}>
                    <div
                        className='bg-gradient-to-r from-cyan-500 to-blue-500 flex flex-col gap-2 rounded-2xl py-2 px-4 text-white w-[90%]'>
                        <Typography variant="h4">
                            Zaloguj się
                        </Typography>
                        <Typography className="mt-1 font-normal">
                            Witaj ponownie, podaj dane logowania.
                        </Typography>
                    </div>

                    <form className="mt-4 mb-2 w-80 max-w-screen-lg sm:w-96 flex flex-col px-4">
                        <div className="mb-1 flex flex-col gap-3">
                            <Typography variant="h6" color="blue-gray" className="-mb-3">
                                Email
                            </Typography>
                            <Input
                                {...register("email", {
                                    required: 'Email jest wymagany.',
                                    // pattern: {
                                    //     value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    //     message: "Niepoprawny format emaila.",
                                    // }, //todo
                                    maxLength: {
                                        value: 64,
                                        message: 'Email zbyt długi.'
                                    }
                                })}
                                type='email'
                                error={!!errors.email}
                                className={!!errors.email ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                                size="lg"
                                placeholder="janusz@mail.com"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                            {errors.email && <div className='auth-form-err'>{errors.email.message}</div>}

                            <Typography variant="h6" color="blue-gray" className="-mb-3">
                                Hasło
                            </Typography>
                            <Input
                                {...register("password", {
                                    required: 'Hasło jest wymagane.'
                                })}
                                error={!!errors.password}
                                className={!!errors.password ? '!border-t-red-500 focus:!border-t-red-500' : '!border-t-blue-gray-200 focus:!border-t-blue-gray-900'}
                                type="password"
                                size="lg"
                                placeholder="********"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                            {errors.password && <div className='auth-form-err'>{errors.password.message}</div>}
                            <div onClick={() => setResetOpen(true)}>
                                <Link>Odzyskaj hasło</Link>
                            </div>
                        </div>

                        {errors.root && <div
                            className='auth-form-err text-center !mt-3 border-0.5 !font-medium p-3 rounded-md border-blue-gray-800'>
                            {errors.root.message}
                        </div>}

                        <Button className="bg-gradient-to-r from-cyan-500 to-blue-500 mt-16" fullWidth
                                onClick={handleSubmit(submitForm)}>
                            Zaloguj się
                        </Button>
                        <Typography color="gray" className="mt-4 text-center">
                            Nie masz jeszcze konta?
                            <a href='/rejestracja'>
                                <Button variant='outlined' size='sm' className='ml-1 border-cyan-800 rounded-md'
                                        ripple={false}>
                                    Załóż je
                                </Button>
                            </a>
                        </Typography>
                    </form>
                </Card>
            </div>
        </>
    );
}