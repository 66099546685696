import React from 'react';
import {CardSkeleton, DividerSkeleton, ImageSkeleton, TextSkeleton} from "../components/Skeletons";


// Szkielet dla lewej kolumny profilu studenta
function LeftStudentProfileSkeleton() {
    return (
        <div className='w-full md:w-72 flex flex-col'>
            <CardSkeleton>
                <div className='flex w-full justify-center bg-gray-300 py-3 px-1 rounded-sm'>
                    <div className='w-fit'>
                        {/* Szkielet dla obrazu profilowego */}
                        <ImageSkeleton className="h-48 w-48" />
                        <div className='mt-2 text-center'>
                            {/* Szkielet dla imienia/nazwiska */}
                            <TextSkeleton className="h-4 w-32 mb-2" />
                            {/* Szkielet dla dodatkowych informacji */}
                            <TextSkeleton className="h-3 w-24" />
                        </div>
                    </div>
                </div>
            </CardSkeleton>

            <DividerSkeleton />

            {/* Sekcja z podstawowymi informacjami */}
            <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />

            <DividerSkeleton />

            {/* Dodatkowe informacje */}
            <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
            <TextSkeleton className='h-3 w-20 px-5 mb-2' />
        </div>
    );
}

// Szkielet dla prawej kolumny profilu studenta
function RightStudentProfileSkeleton() {
    return (
        <CardSkeleton>
            <div className='w-full md:px-3 mt-4'>
                {/* Nagłówek */}
                <TextSkeleton className='h-4 w-28 px-2 mt-4 mb-2' />
                <DividerSkeleton />

                {/* Opis studenta */}
                <div className="space-y-4 px-3">
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                    <TextSkeleton className='h-3 w-full mb-2' />
                </div>

                <DividerSkeleton />

                {/* Sekcja z opiniami */}
                <div className='flex flex-row p-2 bg-gray-100 my-2 mx-2 animate-pulse'>
                    <ImageSkeleton className="h-14 w-14" />
                    <div className='px-3'>
                        <TextSkeleton className='h-4 w-28 mb-1' />
                        <TextSkeleton className='h-3 w-60' />
                    </div>
                </div>
                <div className='flex flex-row p-2 bg-gray-100 my-2 mx-2 animate-pulse'>
                    <ImageSkeleton className="h-14 w-14" />
                    <div className='px-3'>
                        <TextSkeleton className='h-4 w-28 mb-1' />
                        <TextSkeleton className='h-3 w-60' />
                    </div>
                </div>
            </div>
        </CardSkeleton>
    );
}

// Szkielet dla całego profilu studenta
function StudentProfileSkeleton() {
    return (
        <CardSkeleton>
            <div className='w-full flex flex-col md:flex-row self-center my-4 px-1 py-1 md:px-6 md:py-3'>
                <LeftStudentProfileSkeleton />
                <RightStudentProfileSkeleton />
            </div>
        </CardSkeleton>
    );
}

export default StudentProfileSkeleton;
