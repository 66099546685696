import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import api from "../utils/api";
import {Typography} from "@material-tailwind/react";
import {useAlert} from "../components/DisimisibleAlert";
import {useNavigate} from "react-router-dom";

const ReportForm = () => {
    const {
        register,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const showAlert = useAlert();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            await api.post('/reports', data);
            navigate('/');
            showAlert('Pomyślnie dodano złgoszenie.');
        } catch (error) {
            if (error.response?.data?.errors) {
                setError('root', {message: error.response?.data?.errors[0]});
            } else {
                setError('root', {message: "Wystąpił nieoczekiwany błąd."});
            }

            console.error(error);
            alert('Wystąpił błąd podczas dodawania zgłoszenia.');
        }
    };

    return (
        <div className='h-[83vh] flex items-center'>
            <div className="w-11/12 md:w-1/3 mx-auto p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold text-center mb-4">Dodaj zgłoszenie</h2>
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Typ zgłoszenia */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="type">Typ zgłoszenia</label>
                        <select
                            {...register('type', {required: 'Wybierz typ zgłoszenia'})}
                            id="type"
                            className={`mt-2 block w-full border border-gray-300 rounded-md p-2 ${errors.type ? 'border-red-500' : ''}`}
                        >
                            <option value="">Wybierz</option>
                            <option value="problem">Błąd</option>
                            <option value="suggestion">Sugestia rozwoju</option>
                            <option value="report">Zgłoszenie</option>
                            <option value="feedback">Opinia</option>
                        </select>
                        {errors.type && <p className="text-red-500 text-sm">{errors.type.message}</p>}
                    </div>

                    {/* Treść zgłoszenia */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="content">Treść
                            zgłoszenia</label>
                        <textarea
                            {...register('content', {required: 'Treść zgłoszenia jest wymagana'})}
                            id="content"
                            rows="4"
                            className={`mt-2 block w-full border border-gray-300 rounded-md p-2 ${errors.content ? 'border-red-500' : ''}`}
                        />
                        {errors.content && <p className="text-red-500 text-sm">{errors.content.message}</p>}
                    </div>

                    {/* Email (dla niezalogowanych użytkowników) */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="email">Adres email</label>
                        <input
                            {...register('email', {
                                required: 'Adres email jest wymagany',
                                pattern: {
                                    value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                                    message: 'Podaj poprawny adres email'
                                }
                            })}
                            id="email"
                            type="email"
                            className={`mt-2 block w-full border border-gray-300 rounded-md p-2 ${errors.email ? 'border-red-500' : ''}`}
                        />
                        {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                    >
                        Dodaj zgłoszenie
                    </button>

                    {errors.root && (
                        <Typography className='auth-form-err !mt-3'>{errors.root.message}</Typography>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ReportForm;
