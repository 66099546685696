import {Button, Typography} from "@material-tailwind/react";
import HowToTabs from "./HowToTabs";
import SearchBar from "../../SearchBar";

const fontColor = 'white'

export default function HomeAbout() {
    return (
        <div
            className='w-full flex-col md:flex-row bg-gradient-to-r from-cyan-500 to-blue-500 rounded-md mt-3 shadow'>

            <div className='flex justify-center'>
                <div className='w-full inline-block lg:hidden p-2 shadow-2xl rounded mt-1 mx-3 bg-gradient-to-r from-blue-500 to-blue-400'>
                    <SearchBar className='w-full'></SearchBar>
                </div>
            </div>

            <div className='flex'>
                <div className='flex-1 md:flex-[2_2_0%] ml-2 mr-2 my-3'>
                    <div>
                        <Typography variant='h5' color={fontColor}>Krótko o nas</Typography>
                        <Typography variant='lead' className='text-sm md:text-xl pt-5 text-center md:text-left'  color={fontColor}>
                            ZdajTo.pl to miejsce, gdzie korepetytorzy i uczniowie spotykają się, aby wspólnie
                            osiągać
                            sukcesy edukacyjne. Nasza platforma umożliwia łatwe wystawianie i przeglądanie ogłoszeń
                            z
                            korepetycjami we wszystkich dziedzinach - od nauk ścisłych, przez języki obce, aż po
                            sztukę
                            i
                            technologie. Niezależnie od tego, czy jesteś doświadczonym nauczycielem, czy uczniem
                            szukającym
                            wsparcia, ZdajTo.pl to idealne miejsce, aby znaleźć to, czego potrzebujesz. Dołącz do
                            naszej
                            społeczności i zacznij zdobywać nowe umiejętności już dziś!
                        </Typography>
                    </div>
                    <div className="pt-3">
                        <a href='/o-serwisie'>
                            <Button className='bg-blue-600 block mx-auto md:inline-block' >Więcej</Button>
                        </a>
                    </div>
                </div>

                <div className="flex-1 md:flex-[1_1_0%] ml-5 mr-2 my-3">
                    <Typography variant="h5" color={fontColor} className="mb-2">
                        Jak działamy?
                    </Typography>
                    <HowToTabs/>
                </div>
            </div>
        </div>
    );
}