import { Button, Card, Checkbox, Dialog, Input, Radio, Textarea, Typography } from "@material-tailwind/react";
import api from "../utils/api";
import { useEffect, useState } from "react";
import SpinnerScreen from "../components/SpinnerScreen";
import { Controller, useForm } from "react-hook-form";
import { useResponsive } from "../components/ResponsiveProvider";
import { SearchInput } from "../components/SearchInput";
import { useErrorBoundary } from "react-error-boundary";
import { useParams } from 'react-router-dom';  // To get the announcement ID

export default function EditAnnouncementStudent() {
    const { showBoundary } = useErrorBoundary();
    const { isMobile } = useResponsive();
    const { id } = useParams(); // Get the ID from URL

    const [showDialog, setShowDialog] = useState(false);

    const { control, handleSubmit, register, setError, setValue, formState: { errors, isSubmitting } } = useForm();
    const [data, setData] = useState({
        categories: [],
        announcement: null, // To store announcement data for editing
    });
    const [fetching, setFetching] = useState({
        categories: true,
        announcement: true,
    });

    const textAreaMaxLen = 2500
    const [textAreaLength, setTextAreaLength] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch categories
                const categoriesRes = await api.get('/categories');
                const categories = categoriesRes.data.map(c => {
                    return { label: c.category_name, key: c.id };
                });

                setData((prevData) => ({
                    ...prevData,
                    categories,
                }));

                setFetching((prevFetching) => ({
                    ...prevFetching,
                    categories: false,
                }));

                // Fetch announcement data
                const announcementRes = await api.get(`/student-announcement/${id}`);
                const announcementData = announcementRes.data;

                // Fill the form with fetched data
                setValue('category', announcementData.category_id);
                setValue('city', announcementData.city_id);
                setValue('lesson-length', announcementData.lesson_length);
                setValue('price', announcementData.price);
                setValue('level', announcementData.level);
                setValue('description', announcementData.description);

                setTextAreaLength(announcementData.description?.length);

                setData((prevData) => ({
                    ...prevData,
                    announcement: announcementData,
                }));

                setFetching((prevFetching) => ({
                    ...prevFetching,
                    announcement: false,
                }));
            } catch (e) {
                setFetching({ categories: false, announcement: false });
                showBoundary(e);
            }
        };

        fetchData();
    }, [id, setValue, showBoundary]);

    async function submitForm(formData) {
        try {
            const apiData = {
                category_id: formData.category || null,
                city_id: formData.city || null,
                lesson_length: parseInt(formData['lesson-length']),
                price: parseInt(formData.price),
                education_scope: formData.level,
                teaching_locations: {
                    tutor: formData.tutor || false,
                    student: formData.student || false,
                    online: formData.online || false,
                },
                description: formData.description,
            };

            // Send PUT request to update the announcement
            const res = await api.put(`/student-announcement/${id}`, apiData);
            if (res.status === 200) {
                setShowDialog(true);
            }
        } catch (e) {
            setError('root', {
                message: e.response?.data?.errors?.[0] || 'Wystąpił błąd podczas edytowania ogłoszenia.',
            });
        }
    }

    if (fetching.categories || fetching.announcement) {
        return <SpinnerScreen />;
    }

    return (
        <>
            {showDialog && (
                <Dialog open={true}>
                    <div className='p-8'>
                        <Typography variant='h3'>
                            Sukces! Twoje ogłoszenie zostało zaktualizowane!
                        </Typography>
                        <Button variant="gradient" color="green" onClick={() => window.location.replace('/')}
                                className='mt-6 self-center'>
                            <span>Strona główna</span>
                        </Button>
                    </div>
                </Dialog>
            )}

            <div className="flex justify-center w-full">
                <Card className="w-full md:w-4/5 lg:w-1/2 p-10 my-6 flex flex-col gap-4 md:shadow-xl"
                      shadow={!isMobile}>
                    <div>

                        <Typography variant='h3' className='text-center mb-2'>
                            Edytuj ogłoszenie ucznia
                        </Typography>

                        <Typography variant="paragraph" className="text-blue-gray-700 font-bold">
                            Przedmiot
                        </Typography>

                        <Controller
                            name="category"
                            control={control}
                            rules={{ required: 'Wybór przedmiotu jest wymagany.' }}
                            render={({ field }) => (
                                <>
                                    <SearchInput value={field.value} onChange={field.onChange}
                                                 onBlur={field.onBlur} data={data.categories} />
                                    {errors.category &&
                                        <div className='add-tutor-announcement-form-err'>{errors.category.message}</div>}
                                </>
                            )}
                        />
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Miasto
                        </Typography>
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: 'Miasto jest wymagane.' }}
                            render={({ field }) => (
                                <>
                                    <SearchInput minCharacters={3} value={field.value} onChange={field.onChange}
                                                 onBlur={field.onBlur} fetcher={async (inputValue) => {
                                        if (inputValue.length < 3) {
                                            return [];
                                        }

                                        const res = await api.get(`/cities/${inputValue}`);
                                        const data = res.data;

                                        return data.map(d => {
                                            return { label: d.city, key: d.id, subLabel: d.state }
                                        })
                                    }} />
                                    {errors.city &&
                                        <div className='add-tutor-announcement-form-err'>{errors.city.message}</div>}
                                </>
                            )}
                        />
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 font-bold">
                            Długość lekcji
                        </Typography>
                        <Input
                            {...register('lesson-length', {
                                required: 'Długość lekcji jest wymagana.',
                                min: { value: 1, message: 'Lekcja musi trwać przynajmniej 1 minutę.' }
                            })}
                            variant="static"
                            type='number'
                            placeholder="Czas lekcji w minutach"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {errors["lesson-length"] &&
                            <div className='add-tutor-announcement-form-err'>{errors["lesson-length"].message}</div>}
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Cena
                        </Typography>
                        <Input
                            {...register('price', {
                                required: 'Cena jest wymagana.',
                                min: { value: 0.01, message: 'Cena musi być większa niż 0.' },
                            })}
                            variant="static"
                            type='number'
                            placeholder="Cena w złotówkach"
                        />
                        {errors.price && <div className='add-tutor-announcement-form-err'>{errors.price.message}</div>}
                    </div>

                    <div className='flex flex-col md:flex-row justify-around'>
                        <div className="flex flex-col">
                            <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                                Zakres nauczania
                            </Typography>
                            <Radio label="Przedszkole" {...register('level')} value='kindergarten' />
                            <Radio label="Szkoła podstawowa" {...register('level')} value='primarySchool' />
                            <Radio label="Szkoła średnia" {...register('level')} value='highSchool' />
                            <Radio label="Studia" {...register('level')} value='collage' />
                        </div>

                        <div className="flex flex-col">
                            <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                                Tryb udzielania korepetycji
                            </Typography>
                            <Checkbox label="U Korepetytora" {...register('tutor')} />
                            <Checkbox label="U ucznia" {...register('student')} />
                            <Checkbox label="Online" {...register('online')} />
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <Typography variant="paragraph" className="text-blue-gray-700 font-bold mt-3">
                            Opis
                        </Typography>
                        <Textarea
                            {...register('description', {
                                required: 'Opis jest wymagany.',
                                minLength: { value: 100, message: 'Opis zbyt krótki.' },
                                maxLength: { value: 5000, message: 'Opis zbyt długi.' },
                                onChange: (e) => setTextAreaLength(e.target.value.length)
                            })}
                            label="Długość opisu - od 100 znaków"
                            rows={5}
                        />
                        <Typography
                            className='text-xs ml-auto'>{textAreaLength} /{textAreaMaxLen}</Typography>
                        {errors.description && <div className='add-tutor-announcement-form-err'>{errors.description.message}</div>}
                    </div>

                    <Button variant="gradient" color="green" onClick={handleSubmit(submitForm)} disabled={isSubmitting} className="mt-4">
                        <span>Zaktualizuj ogłoszenie</span>
                    </Button>
                </Card>
            </div>
        </>
    );
}
