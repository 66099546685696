import { ConversationBox } from "./ConversationBox";
import {createApiLink} from "../../../utils/functions";

export function ConversationList({ conversations, userId, selectedId }) {
    return conversations.map((c) => {
        const isChosen = selectedId == c.id;
        const imageUrl = createApiLink(userId == c.user_id_1 ? c.user2_profile_image : c.user1_profile_image);
        const boxName = userId == c.user_id_1
            ? `${c.user2_firstname} ${c.user2_lastname}`
            : `${c.user1_firstname} ${c.user1_lastname}`;

        return (
            <ConversationBox
                key={c.id}
                topic={c.topic}
                name={boxName}
                id={c.id}
                image={imageUrl}
                chosen={isChosen}
                hasUnread={c.has_unread}
            />
        );
    });
}
