import Filters from "../components/pageParts/announcements/Filters";
import AnnouncementsComp from "../components/pageParts/announcements/AnnouncementsComp";
import {createContext, Suspense} from "react";
import useGetData from "../utils/useGetData";
import {useSearchParams} from "react-router-dom";
import {FiltersFormProvider} from "../components/pageParts/announcements/FiltersFormProvider";
import {DefaultSkeletonPage} from "../components/Skeletons";
import {createApiLink} from "../utils/functions";

export const AnnouncementDataContext = createContext(
    {states: null, categories: null,  announcements: []}
);

function DataProvider({children}) {
    let [searchParams, setSearchParams] = useSearchParams();

    const categories = useGetData(createApiLink('/categories'));
    const announcementsData = useGetData(createApiLink(`/tut-announcements/filter?${searchParams || ''}`));

    return (
        <AnnouncementDataContext.Provider value={{categories, announcementsData }}>
            {children}
        </AnnouncementDataContext.Provider>
    );
}

export default function Announcements() {
    return (
        <Suspense fallback={<DefaultSkeletonPage />}>
            <DataProvider>
                <FiltersFormProvider>
                    <div className='px-3 py-4 md:py-1 max-w-full'>
                        <div className='flex flex-col md:flex-row'>
                            <Filters/>
                            <AnnouncementsComp/>
                        </div>
                    </div>
                </FiltersFormProvider>
            </DataProvider>
        </Suspense>
    )
}