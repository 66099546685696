import {Button, Card, Checkbox, Dialog, Input, Textarea, Typography} from "@material-tailwind/react";
import api from "../utils/api";
import {useEffect, useState} from "react";
import SpinnerScreen from "../components/SpinnerScreen";
import {Controller, useForm} from "react-hook-form";
import {useResponsive} from "../components/ResponsiveProvider";
import {SearchInput} from "../components/SearchInput";
import {useErrorBoundary} from "react-error-boundary";
import NestingAccordion from "../components/NestingAccordion";

export default function AddAnnouncementTutor() {
    const {showBoundary} = useErrorBoundary();
    const {isMobile} = useResponsive();

    const [showDialog, setShowDialog] = useState(false);

    const {control, handleSubmit, register, setError, formState: {errors, isSubmitting}} = useForm();
    const [data, setData] = useState({
        categories: [],
    });
    const [fetching, setFetching] = useState({
        categories: true,
    });

    const textAreaMaxLen = {
        shortDescription: 250,
        description: 2500
    }

    const [textAreaLengths, setTextAreaLengths] = useState({
        shortDescription: 0,
        description: 0
    });

    const [availability, setAvailability] = useState({
        monday: {enabled: false, from: '', to: ''},
        tuesday: {enabled: false, from: '', to: ''},
        wednesday: {enabled: false, from: '', to: ''},
        thursday: {enabled: false, from: '', to: ''},
        friday: {enabled: false, from: '', to: ''},
        saturday: {enabled: false, from: '', to: ''},
        sunday: {enabled: false, from: '', to: ''},
    });

    const dayTranslations = {
        monday: 'Poniedziałek',
        tuesday: 'Wtorek',
        wednesday: 'Środa',
        thursday: 'Czwartek',
        friday: 'Piątek',
        saturday: 'Sobota',
        sunday: 'Niedziela',
    };

    const handleAvailabilityChange = (day, field, value) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                [field]: value
            }
        }));
    };

    const toggleDayAvailability = (day) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                enabled: !prev[day].enabled
            }
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoriesRes = await api.get('/categories');

                setData({
                    categories: categoriesRes.data.map(c => {
                        return {label: c.category_name, key: c.id};
                    })
                });

                setFetching({categories: false});
            } catch (e) {
                setFetching({categories: false});
                showBoundary(e);
            }
        };

        fetchData();
    }, [showBoundary]);


    async function submitForm(formData) {
        try {
            const apiData = {
                category_id: formData.category || null,
                city_id: formData.city || null,
                lesson_length: parseInt(formData['lesson-length']),
                price: parseInt(formData.price),
                levels: {
                    kindergarten: formData.kindergarten || false,
                    primarySchool: formData.primarySchool || false,
                    highSchool: formData.highSchool || false,
                    collage: formData.collage || false,
                },
                teaching_locations: {
                    tutor: formData.tutor || false,
                    student: formData.student || false,
                    online: formData.online || false,
                },
                description: formData.description,
                shortDescription: formData.shortDescription,
                availability: availability
            };

            const res = await api.post('/add-tut-announcement', apiData);
            if (res.status === 201) {
                setShowDialog(true);
            }
        } catch (e) {
            setError('root', {
                message: e.response?.data?.errors?.[0] || 'Wystąpił błąd podczas dodawania ogłoszenia.',
            });
        }
    }

    if (fetching.categories) {
        return <SpinnerScreen/>;
    }

    return (
        <>
            {showDialog && (
                <Dialog open={true}>
                    <div className='p-8'>
                        <Typography variant='h3'>
                            Sukces! Twoje ogłoszenie zostało dodane!
                        </Typography>
                        <Button variant="gradient" color="green" onClick={() => window.location.replace('/')}
                                className='mt-6 self-center'>
                            <span>Strona główna</span>
                        </Button>
                    </div>
                </Dialog>
            )}
            <div className="flex justify-center w-full">
                <Card className="w-full md:w-4/5 lg:w-1/2 p-10 my-6 flex flex-col gap-4 md:shadow-xl"
                      shadow={!isMobile}>

                    <Typography variant='h3' className='text-center mb-2'>
                        Dodaj ogłoszenie korepetytora
                    </Typography>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 font-bold">
                            Przedmiot
                        </Typography>

                        <Controller
                            name="category"
                            control={control}
                            rules={{required: 'Wybór przedmiotu jest wymagany.'}}
                            render={({field}) => (
                                <>
                                    <SearchInput value={field.value} onChange={field.onChange}
                                                 onBlur={field.onBlur} data={data.categories}/>
                                    {errors.category &&
                                        <div
                                            className='add-tutor-announcement-form-err'>{errors.category.message}</div>}
                                </>
                            )}
                        />
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Miasto
                        </Typography>
                        <Controller
                            name="city"
                            control={control}
                            rules={{required: 'Miasto jest wymagane.'}}
                            render={({field}) => (
                                <>
                                    <SearchInput minCharacters={3} value={field.value} onChange={field.onChange}
                                                 onBlur={field.onBlur} fetcher={
                                        async (inputValue) => {
                                            if (inputValue.length < 3) {
                                                return [];
                                            }

                                            const res = await api.get(`/cities/${inputValue}`);
                                            const data = res.data;

                                            return data.map(d => {
                                                return {label: d.city, key: d.id, subLabel: d.state}
                                            })
                                        }
                                    }/>
                                    {errors.city &&
                                        <div className='add-tutor-announcement-form-err'>{errors.city.message}</div>}
                                </>
                            )}
                        />
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 font-bold">
                            Długość lekcji
                        </Typography>

                        <Input
                            {...register('lesson-length', {
                                required: 'Długość lekcji jest wymagana.',
                                min: {value: 1, message: 'Lekcja musi trwać przynajmniej 1 minutę.'}
                            })}
                            variant="static"
                            type='number'
                            placeholder="Czas lekcji w minutach"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {errors["lesson-length"] &&
                            <div className='add-tutor-announcement-form-err'>{errors["lesson-length"].message}</div>}
                    </div>

                    <div>
                        <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                            Cena
                        </Typography>

                        <Input
                            {...register('price', {
                                required: 'Cena jest wymagana.',
                                min: {value: 0.01, message: 'Cena musi być większa niż 0.'},
                            })}
                            variant="static"
                            type='number'
                            placeholder="Cena w złotówkach"
                        />
                        {errors.price && <div className='add-tutor-announcement-form-err'>{errors.price.message}</div>}
                    </div>

                    <div className='flex flex-col md:flex-row justify-around'>
                        <div className="flex flex-col">
                            <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                                Zakres nauczania
                            </Typography>
                            <Checkbox label="Przedszkole" {...register('kindergarten')}/>
                            <Checkbox label="Szkoła podstawowa" {...register('primarySchool')}/>
                            <Checkbox label="Szkoła średnia" {...register('highSchool')}/>
                            <Checkbox label="Studia" {...register('collage')}/>
                        </div>

                        <div className="flex flex-col">
                            <Typography variant="paragraph" className="text-blue-gray-700 mt-3 font-bold">
                                Tryb udzielania korepetycji
                            </Typography>
                            <Checkbox label="U Korepetytora" {...register('tutor')}/>
                            <Checkbox label="U ucznia" {...register('student')}/>
                            <Checkbox label="Online" {...register('online')}/>
                        </div>
                    </div>

                    <NestingAccordion header='Dostępność' className='py-4'>
                        <div>
                            {Object.keys(availability).map(day => (
                                <div key={day} className="flex flex-col md:flex-row items-center md:gap-8">
                                    <Checkbox label={dayTranslations[day]}
                                              checked={availability[day].enabled}
                                              onChange={() => toggleDayAvailability(day)}/>
                                    {availability[day].enabled && (
                                        <div className="flex gap-2 items-center">
                                            <Input
                                                type="time"
                                                label="Od"
                                                value={availability[day].from}
                                                onChange={(e) => handleAvailabilityChange(day, 'from', e.target.value)}
                                            />
                                            <Input
                                                type="time"
                                                label="Do"
                                                value={availability[day].to}
                                                onChange={(e) => handleAvailabilityChange(day, 'to', e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </NestingAccordion>

                    <Textarea
                        label="Opis ogłoszenia"
                        containerProps={{className: "mt-3"}}
                        {...register('description', {
                            required: 'Opis ogłoszenia jest wymagany.',
                            minLength: {value: 200, message: 'Opis musi mieć przynajmniej 200 znaków.'},
                            maxLength: {value: 2500, message: 'Opis jest zbyt długi.'},
                            onChange: (e) => setTextAreaLengths((p) => ({...p, description: e.target.value.length}))
                        })}
                    />
                    <Typography
                        className='text-xs -mt-3 ml-auto'>{textAreaLengths.description} /{textAreaMaxLen.description}</Typography>
                    {errors.description &&
                        <div className='add-tutor-announcement-form-err'>{errors.description.message}</div>}

                    <Textarea
                        label="Skrócona treść ogłoszenia"
                        containerProps={{className: "mt-3"}}
                        {...register('shortDescription', {
                            required: 'Skrócona treść ogłoszenia jest wymagana.',
                            minLength: {value: 30, message: 'Skrócona treść musi mieć przynajmniej 30 znaków.'},
                            maxLength: {value: 250, message: 'Skrócona treść może mieć maksymalnie 250 znaków.'},
                            onChange: (e) => setTextAreaLengths((p) => ({...p, shortDescription: e.target.value.length}))
                        })}
                    />
                    <Typography
                        className='text-xs -mt-3 ml-auto'>{textAreaLengths.shortDescription} /{textAreaMaxLen.shortDescription}</Typography>
                    {errors.shortDescription &&
                        <div className='add-tutor-announcement-form-err'>{errors.shortDescription.message}</div>}

                    {errors.root && <div className='add-tutor-announcement-form-err'>{errors.root.message}</div>}
                    <Button disabled={isSubmitting} className="my-3" onClick={handleSubmit(submitForm)}>
                        {isSubmitting ? 'Dodawanie ogłoszenia...' : 'Dodaj ogłoszenie'}
                    </Button>
                </Card>
            </div>
        </>
    );
}