import {Button, Dialog, Typography} from "@material-tailwind/react";

export default function AuthSuccessDialog({open, header}) {
    return (
        open && (
            <Dialog
                open={open}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}
            >
                <div className='p-8'>
                    <Typography variant='h3'>
                        {header}
                    </Typography>
                    <Button
                        variant="gradient"
                        color="green"
                        onClick={() => window.location.replace('/')}
                        className='mt-6 self-center'
                    >
                        <span>Strona główna</span>
                    </Button>
                </div>
            </Dialog>
        )
    )
}