import { Typography } from "@material-tailwind/react";

export function Message({ value, me }) {
    return (
        <div className={`${me && 'ml-auto'} block my-2 max-w-[60%]`}>
            <Typography
                className={`break-words rounded-xl px-4 py-2 bg-gray-300 w-fit max-w-full ${me && 'ml-auto text-white bg-blue-600'}`}>
                {value}
            </Typography>
        </div>
    );
}
