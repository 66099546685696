import {Button, Typography} from "@material-tailwind/react";

export default function ErrorScreen({error, resetErrorBoundary }) {
    return (
        <div className='w-full min-h-[80vh] flex flex-col justify-center items-center'>
            <Typography className="text-center" variant='h4'>
                {error.userMessage ? error.userMessage : (<>Wystąpił błąd <br /> Nie przejmuj się, po naszej stronie.</>)}
            </Typography>
            <Button className='mt-4' onClick={resetErrorBoundary} size={"sm"}>Spróbuj ponownie</Button>
        </div>
    );
}