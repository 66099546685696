import {useEffect, useState} from "react";
import {Button, Card, CardBody, IconButton, Typography} from "@material-tailwind/react";
import api from "../utils/api";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {useErrorBoundary} from "react-error-boundary";
import {useAlert} from "../components/DisimisibleAlert";
import {getNotificationName} from "../utils/functions";

function NotificationsPagination({ pages, setPage, currentPage }) {
    const [active, setActive] = useState(currentPage);

    const next = () => {
        if (active === pages) return;
        setActive(active + 1);
    };

    const prev = () => {
        if (active === 1) return;
        setActive(active - 1);
    };

    useEffect(() => {
        setPage(active);
    }, [active]);

    useEffect(() => {
        setActive(currentPage);
    }, [currentPage]);

    return (
        <div className="flex items-center gap-8 select-none">
            <IconButton
                size="sm"
                variant="outlined"
                onClick={prev}
                disabled={active === 1}
            >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
                Strona <strong className="text-gray-900">{active}</strong> z{" "}
                <strong className="text-gray-900">{pages}</strong>
            </Typography>
            <IconButton
                size="sm"
                variant="outlined"
                onClick={next}
                disabled={active === pages}
            >
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
        </div>
    );
}

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [readNotificationsFilterOn, setReadNotificationsFilterOn] = useState(false);

    const itemsPerPage = 6;
    const {showBoundary} = useErrorBoundary();

    const showAlert = useAlert();

    const fetchNotifications = async (page = 1) => {
        try {
            const response = await api.get(`/notifications/${itemsPerPage}/${page}`);

            setNotifications(response.data.notifications);
            setTotalPages(response.data.totalPages);
            setCurrentPage(page);
        } catch (error) {
            showBoundary(error);
        }
    };

    const markAllAsRead = async () => {
        try {
            await api.get(`/notifications/read`);
            fetchNotifications(currentPage);
        } catch (error) {
            showAlert('Nie udało się oznaczyć wiadomości jako przeczytane.')
        }
    };
    const markAsRead = async (notificationId) => {
        try {
            await api.get(`/notification/read/${notificationId}`);
            fetchNotifications(currentPage);
        } catch (error) {
            showAlert('Nie udało się oznaczyć wiadomości jako przeczytanej.')
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const handlePageChange = (page) => {
        fetchNotifications(page);
    };

    return (
        <div className="w-full max-w-3xl mx-auto mt-8 min-h-[80vh]">
            <Typography variant="h4" className="mb-4 text-center font-bold">
                Powiadomienia
            </Typography>
            <div className="space-y-4">
                <div className="flex justify-end">
                    <Button
                        size="sm"
                        variant="text"
                        color="blue"
                        onClick={() => markAllAsRead(notifications.map((n) => n.id))}
                        disabled={notifications.every((n) => n.is_read)}
                    >
                        Oznacz wszystkie jako przeczytane
                    </Button>
                </div>

                <div className='flex cursor-pointer ml-3'>
                    <Typography className={`text-xs hover:text-blue-200 ${!readNotificationsFilterOn && 'text-blue-600'}`} as='button' onClick={() => setReadNotificationsFilterOn(false)}>
                        Przeczytane
                    </Typography>

                    <Typography className='text-xs'>/</Typography>

                    <Typography className={`text-xs hover:text-blue-200 ${readNotificationsFilterOn && 'text-blue-600'}`} as='button' onClick={() => setReadNotificationsFilterOn(true)}>
                        Nieprzeczytane
                    </Typography>
                    {/*//todo*/}
                </div>

                {notifications.map((notification) => (
                    <Card
                        key={notification.id}
                        className={`border !m-1 ${
                            notification.is_read ? "border-gray-300" : "border-blue-500"
                        }`}
                    >
                        <CardBody className="px-4 py-2 flex justify-between items-start">
                            {/* Treść powiadomienia */}
                            <div>
                                <Typography variant="h6" className="font-bold">
                                    {getNotificationName(notification.type)}
                                </Typography>
                                <Typography variant="paragraph" className="text-gray-600">
                                    {notification.content}
                                </Typography>
                                <Typography variant="small" className="text-gray-400 mt-2">
                                    {new Date(notification.created_at).toLocaleString()}
                                </Typography>
                            </div>

                            {!notification.is_read && (
                                <Button
                                    size="sm"
                                    variant="text"
                                    color="blue"
                                    className="ml-4"
                                    onClick={() => markAsRead(notification.id)}
                                >
                                    Oznacz jako przeczytane
                                </Button>
                            )}
                        </CardBody>
                    </Card>
                ))}
            </div>
            <div className="mt-6 flex justify-center">
                <NotificationsPagination
                    pages={totalPages}
                    currentPage={currentPage}
                    setPage={handlePageChange}
                />
            </div>
        </div>
    );
};

export default Notifications;